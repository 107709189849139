import { defineStore } from "pinia";
import mushroom from "cem-probe-api";
import { Filter } from "mushroomjs";
let _ = require("lodash");
export const listMenu = defineStore({
  id: "listMenu",
  state: () => ({
    menuServices: [],
    menuUsecases: [],
    menuPhanCap: [],
  }),
  actions: {
    async getListMenuService() {
      try {
        const responseRoles = JSON.parse(localStorage.getItem("userInfo"));
        const response = await mushroom.service.getAllAsync({
          filters: responseRoles.result.roles.includes("Admin")
            ? Filter.ne("disabled", true).ne("is_menu_hidden", true).build()
            : Filter.ne("is_menu_hidden", true).build(),
        });
        if (response.result) {
          response.result.forEach((item) => {
            if (item.avatar_id) {
              item.url = mushroom.$file.linkBuilder.thumb
                .id(item.avatar_id)
                .stretch(20, 20)
                .build();
            }
          });
          this.menuServices = response.result;
        }
      } catch (e) {
        console.error("Có lỗi: %o", e);
      }
    },

    async getListMenuUsecases() {
      try {
        const responseRoles = JSON.parse(localStorage.getItem("userInfo"));
        const response = await mushroom.usecase.getAllAsync({
          filters: responseRoles.result.roles.includes("Admin")
            ? Filter.ne("disabled", true).ne("is_menu_hidden", true).build()
            : Filter.ne("is_menu_hidden", true).build(),
        });
        if (response.result) {
          response.result.forEach((item) => {
            if (item.avatar_id) {
              item.url = mushroom.$file.linkBuilder.thumb
                .id(item.avatar_id)
                .stretch(20, 20)
                .build();
            }
          });
          this.menuUsecases = response.result;
        }
      } catch (e) {
        console.error("Có lỗi: %o", e);
      }
    },
    async isMenuParent(menu) {
      if (!menu || !menu.id) return;
      try {
        const response = await mushroom.menu.listAsync({
          filters: Filter.eq("parent_id", menu.id).build(),
        });
        if (response.result?.length > 0) return true;
        return false;
      } catch (e) {
        console.error("Có lỗi: %o", e);
      }
    },
    async getListMenuPhanCap() {
      const responseRoles = JSON.parse(localStorage.getItem("userInfo"));
      const commonElements = _.intersection(
        ["Admin", "AdminPrimary"],
        responseRoles.result.roles
      );
      if (commonElements.length === 0) return;
      try {
        const response = await mushroom.menu.getAllAsync({
          filters: Filter.eq(
            "target_domain",
            localStorage.getItem("type_login") || "probe"
          )
            .ne("target_type", "static")
            .build(),
          fields:
            "id,label,label_lang,parent_id,visible,target_domain,target_type,target_id",
        });

        let arr_temp = [];
        let arrMenusNotHaveParentId = response.result.filter(
          (item) => !item.parent_id
        );
        arrMenusNotHaveParentId.forEach((menu) => {
          let obj = {
            id: "",
            label: "",
            targetId: "",
            targetType: "",
          };
          obj.id =
            menu.target_type + ":" + menu.id + "-targetId:" + menu.target_id;
          obj.label = menu.label;
          obj.targetId = menu.target_id;
          obj.targetType = menu.target_type;
          arr_temp.push(obj);
        });

        let arrMenusHaveParentId = response.result.filter(
          (item) => item.parent_id
        );
        let arrayParentIds = arrMenusHaveParentId.map((item) => item.parent_id);
        const resParentMenus = await mushroom.menu.getAllAsync({
          filters: Filter.in("id", arrayParentIds).build(),
        });
        resParentMenus.result.forEach((item) => {
          arrMenusHaveParentId.forEach((menu) => {
            if (item.id === menu.parent_id) {
              let obj = {
                id: "",
                label: "",
                targetId: "",
                targetType: "",
              };
              obj.id =
                menu.target_type +
                ":" +
                menu.id +
                "-targetId:" +
                menu.target_id;
              obj.label = item.visible
                ? item.label + " - " + menu.label
                : item.label;
              obj.targetId = menu.target_id;
              obj.targetType = menu.target_type;
              arr_temp.push(obj);
            }
          });
        });
        let a = _.uniqBy(arr_temp, "label");
        this.menuPhanCap = _.sortBy(a, [
          function (o) {
            return o.label.toLowerCase();
          },
        ]);
      } catch (e) {
        console.error("Có lỗi: %o", e);
      }
    },
  },
  getters: {
    showListMenuService(state) {
      return state.menuServices;
    },
    showListMenuUsecases(state) {
      return state.menuUsecases;
    },
    showListMenuPhanCap(state) {
      return state.menuPhanCap;
    },
  },
});

<script setup>
import { onMounted, ref, reactive, watch } from "vue";
import mushroom from "cem-probe-api";
import Layout from "../../../layouts/main.vue";
import { useRouter, useRoute } from "vue-router";
import MethodService from "../../../service/MethodService";
import DataForm from "./dataFormKH";
import { generalKhachHang } from "../../../stores/generalKhachHang";
import Loading from "../../../components/loading-hello.vue";
import SearchInfinite from "../../../components/SearchInfinite.vue";
import { datePicker } from "../../../stores/datePicker";
import { abortAPI } from "@/stores/abortAPI";

const tableRules = reactive(MethodService.copyObject(DataForm.tableRules));
const loadingData = ref(false);
const generalKH = generalKhachHang();
const router = useRouter();
const route = useRoute();
const checkThreshold = ref([4]);
const checkService = ref([]);
const form = reactive({ text: "" });
const textSearch = reactive({ text: "" });
const countUser = ref([0]);
const dataSearch = ref([]);
const isSearch = ref(false);
const loadingState = ref(""); // hiển thị loading khi gọi api tìm kiếm
const inputSearch = ref(null);
const storeDatePicker = datePicker();
const storeAbortAPI = abortAPI();
const abortController = new AbortController();

// phân trang
const fn_tableSizeChange = (limit) => {
  tableRules.limit = limit;
  fn_tableChangeOffset(tableRules.page);
};
const fn_tableCurentChange = (page) => {
  fn_tableChangeOffset(page);
};
const fn_tablePrevClick = (page) => {
  fn_tableChangeOffset(page);
};
const fn_tableNextClick = (page) => {
  fn_tableChangeOffset(page);
};
const fn_tableChangeOffset = (page) => {
  tableRules.page = page;
  tableRules.offset = (tableRules.page - 1) * tableRules.limit;
  getTableData(checkService.value, storeDatePicker.datePicker);
};

watch(
  () => storeDatePicker.showDatePicker,
  (newDate) => {
    if (!newDate) return;
    getTableData(checkService.value, newDate);
  }
);

const handleUser = (scope, x) => {
  generalKH.tableData.columns.forEach((item, index) => {
    if (item.is_identity) {
      countUser.value = index;
    }
  });
  generalKH.getProbes(localStorage.pid);

  if (x.service_id) {
    router.push({
      path: "/customers/session",
      query: {
        customer: scope.row[countUser.value],
        quality_levels: checkThreshold.value,
        service_id: x.service_id,
      },
    });
  }
};

const handleClick = (type, scope) => {
  generalKH.tableData.columns.forEach((item, index) => {
    if (item.is_identity) {
      countUser.value = index;
    }
  });
  if (type == "dashboard") {
    router.push({
      path: "/customers/dashboard",
      query: { customer: scope.row[countUser.value] },
    });
  }
  if (type == "dashboard-timespan") {
    router.push({
      path: "/customers/dashboard-timespan",
      query: { customer: scope.row[countUser.value] },
    });
  }
};

const checkChangeKeySearch = (keySearch) => {
  isSearch.value = false;
  if (window.waitSearch) clearTimeout(window.waitSearch);
  window.waitSearch = setTimeout(() => {
    getSuggestSearch(keySearch);
  }, 700);
};

const getSuggestSearch = async (keySearch) => {
  if (!keySearch) return;
  loadingState.value = "loading";
  isSearch.value = true;
  const body = {
    service_ids: checkService.value,
    probe_id: localStorage.pid,
    quality_levels: checkThreshold.value,
    search_key: keySearch,
    limit: 20,
    offset: 0,
    start_date: storeDatePicker.datePicker[0],
    stop_date: storeDatePicker.datePicker[1],
  };
  try {
    const response = await mushroom.probe_data.getSumDataCustomerAsync({
      body: body,
      settings: {
        abortController,
      },
    });
    dataSearch.value = [];
    if (response.result.data.length > 0) {
      const keySearchs = keySearch.split(" -- ");
      response.result.data.forEach((row) => {
        row.some((cell, index) => {
          if (response.result.columns[index].search_enable !== true)
            return false;

          return keySearchs.some((key) => {
            if (cell.toString().toLowerCase().includes(key)) {
              const value = row
                .filter((c, i) => response.result.columns[i].search_enable)
                .join(" -- ");
              dataSearch.value.push({
                key: value,
                label: value,
              });
              return true;
            }
            return false;
          });
        });
      });
      dataSearch.value = new Set(dataSearch.value);
    }
  } catch (e) {
    MethodService.showError(e.code);
  } finally {
    loadingState.value = "";
  }
};
const keySearch = (data) => {
  inputSearch.value.focus();
  textSearch.text = data;
  isSearch.value = false;
  onSubmit(checkService.value);
};
const clearValueSearch = () => {
  isSearch.value = false;
  dataSearch.value = [];
};
const onSubmit = (data) => {
  form.text = textSearch.text;
  getTableData(data, storeDatePicker.datePicker);
  isSearch.value = false;
};

const changeService = (e) => {
  if (checkService.value.length > 0) {
    getTableData(e, storeDatePicker.datePicker);
  }
};

const changethresholdName = () => {
  getTableData(checkService.value, storeDatePicker.datePicker);
};

const downloadInfoCardItem = async (cardTitle) => {
  const dataFilter = {
    service_ids: checkService.value,
    probe_id: localStorage.pid,
    quality_levels: checkThreshold.value,
    search_key: form.text,
    is_download: true,
    limit: tableRules.limit,
    offset: tableRules.offset,
  };

  if (storeDatePicker.datePicker) {
    dataFilter.start_date = storeDatePicker.datePicker[0];
    dataFilter.stop_date = storeDatePicker.datePicker[1];
  }

  await generalKH.getFileSumData(dataFilter);

  if (generalKH.fileSumData.length > 0) {
    var mediaType =
      "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
    var userInp = generalKH.fileSumData;
    var a = document.createElement("a");
    a.href = mediaType + userInp;
    a.download = cardTitle;
    a.click();
    a.remove();
  }
};

const getTableData = async (idService, date) => {
  generalKH.changeThreshold(checkThreshold.value);
  checkService.value = idService;
  const dataFilter = {
    service_ids: checkService.value,
    probe_id: localStorage.pid,
    quality_levels: checkThreshold.value,
    search_key: form.text,
    limit: tableRules.limit,
    offset: tableRules.offset,
    sort_col_id: tableRules.sort,
    sort_type: tableRules.sort_type,
  };
  if (date) {
    dataFilter.start_date = date[0];
    dataFilter.stop_date = date[1];
  }

  const search = location.search.substring(1);

  let queryForPush = Object.fromEntries(new URLSearchParams(search));
  queryForPush.start_date = MethodService.formatDateYYYYMMDD(
    storeDatePicker.showDatePicker[0]
  );
  queryForPush.stop_date = MethodService.formatDateYYYYMMDD(
    storeDatePicker.showDatePicker[1]
  );
  queryForPush.quality_levels = checkThreshold.value;
  queryForPush.search_key = form.text;
  queryForPush.limit = tableRules.limit;
  queryForPush.offset = tableRules.offset;

  router
    .replace({
      name: "GeneralKhachHang",
      query: queryForPush,
    })
    .catch(() => {});
  loadingData.value = true;
  await generalKH.getData(dataFilter);

  if (generalKH.tableData) {
    loadingData.value = false;
  }
};

const setLableColumn = (grp) => {
  if (grp.service_id) {
    return grp.name_service;
  }
  return grp.title;
};

const checkDataDatetime = (x, dataDate) => {
  if (x.data_type === "DateTime") {
    return MethodService.formatDate(dataDate);
  }
  return dataDate;
};

const fn_tableSortChange = (column) => {
  MethodService.tableSortChange(column, tableRules);
  getTableData(checkService.value, storeDatePicker.datePicker);
};

const getInfoURL = () => {
  if (route.query.$sort) {
    let strSort = route.query.$sort;
    let objSort = {};
    if (strSort.includes("-")) {
      let keySort = strSort.toString().replace("-", "");
      objSort[keySort] = -1;
      tableRules.sort = objSort;
    } else {
      objSort[strSort] = 1;
      tableRules.sort = objSort;
    }
    tableRules.defaultSort[0] = tableRules.sort;
    route.query.$sort.indexOf("-") !== -1
      ? (tableRules.defaultSort[1] = "descending")
      : (tableRules.defaultSort[1] = "ascending");
  }
  if (route.query.search_key) {
    form.text = route.query.search_key;
    textSearch.text = route.query.search_key;
  }
  if (route.query.$limit) {
    tableRules.limit = Number(route.query.$limit);
  }
  if (route.query.$offset) {
    tableRules.offset = Number(route.query.$offset);
  }
  tableRules.page = tableRules.offset / tableRules.limit + 1;
};

watch(
  () => storeDatePicker.showDatePicker,
  async () => {
    loadingData.value = true;
    await getTableData(checkService.value, storeDatePicker.datePicker);
  }
);

onMounted(async () => {
  storeAbortAPI.setAbort(abortController);
  if (route.query?.start_date) {
    localStorage.setItem("dateSelect", [
      route.query.start_date,
      route.query.stop_date,
    ]);
    storeDatePicker.setDatePicker([
      route.query.start_date,
      route.query.stop_date,
    ]);
  }
  getInfoURL();
  if (generalKH.valueThreshold.length > 0) {
    checkThreshold.value = generalKH.valueThreshold;
  }
  await generalKH.getSummary();
  await generalKH.getThresholdName();
  await getTableData(generalKH.getCheckService, storeDatePicker.datePicker);
});
</script>

<template>
  <Layout>
    <div class="card">
      <loading v-if="loadingData"></loading>
      <div class="card-header card-header-table">
        <h3 class="text-uppercase mb-0">
          {{ $t("t-table-customers") }}
        </h3>
        <div
          v-show="
            checkService?.length > 0 && generalKH.tableData.data?.length > 0
          "
          class="action action-download button-update"
          :title="`${$t('t-download')}`"
          @click="downloadInfoCardItem('Bảng Tổng hợp')"
        >
          <i class="ri-download-2-line icon-download"></i>
        </div>
      </div>

      <div class="card-body" style="padding: 0 0 15px 0">
        <div class="main-body">
          <div class="main-body-search">
            <el-form
              :model="textSearch"
              label-width="40px"
              style="display: flex"
              @submit.prevent
            >
              <el-input
                v-model="textSearch.text"
                :placeholder="`${$t('t-search')}`"
                clearable
                @input="checkChangeKeySearch(textSearch.text)"
                @clear="clearValueSearch"
                ref="inputSearch"
              />
              <el-button
                type="primary"
                native-type="submit"
                @click="onSubmit(checkService)"
                style="margin-left: 20px"
                >{{ $t("t-search") }}</el-button
              >
            </el-form>
            <search-infinite
              :loadingState="loadingState"
              :dataSearch="dataSearch"
              :isSearch="isSearch"
              @keySearch="keySearch"
            ></search-infinite>
          </div>

          <div class="main-body-checkbox">
            <div class="checkbox-content" style="margin-right: 10px">
              <el-checkbox-group
                :key="item.level"
                v-for="item in generalKH.thresholdName"
                v-model="checkThreshold"
                @change="changethresholdName"
              >
                <el-checkbox :label="item.level" name="type">
                  {{ item.name }}
                </el-checkbox>
              </el-checkbox-group>
            </div>

            <div class="checkbox-content">
              <el-checkbox-group
                :key="item.id"
                v-for="item in generalKH.listNameService"
                v-model="checkService"
                @change="changeService"
              >
                <el-checkbox :label="item.id" name="type">
                  {{ item.name }}
                </el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
          <div
            v-show="
              checkService?.length > 0 && generalKH.tableData.data?.length == 0
            "
            class="general-alert"
          >
            <i class="mdi mdi-database-alert-outline mx-2"></i>
            {{ $t("t-not-data") }}
          </div>
          <div v-show="checkService?.length == 0" class="general-alert">
            <i class="mdi mdi-alert-octagon-outline mx-2"></i>
            {{ $t("t-please-select-service") }}
          </div>
          <div
            v-show="
              checkService?.length > 0 && generalKH.tableData.data?.length > 0
            "
          >
            <el-table
              :data="generalKH.tableData.data"
              border
              stripe
              style="width: 100%"
              class="tooltip-customer"
              @sort-change="fn_tableSortChange"
              :default-sort="{
                prop: tableRules.defaultSort[0],
                order: tableRules.defaultSort[1],
              }"
            >
              <el-table-column
                :label="$t('t-stt')"
                width="60"
                align="center"
                fixed
              >
                <template #default="scope">
                  <div>
                    {{ scope.$index + tableRules.offset + 1 }}
                  </div>
                </template>
              </el-table-column>

              <el-table-column
                v-for="(grp, grpIndex) in generalKH.tableData.colGroups"
                :key="grpIndex"
                :prop="grp.col_id"
                :label="setLableColumn(grp)"
                :min-width="180"
                :fixed="grp.is_identity && 'left'"
                align="center"
                :sortable="grp.sortable ? true : false"
              >
                <template v-if="grp.service_id">
                  <el-table-column
                    v-for="(x, index) in grp.columns"
                    :key="'' + grp.service_id + grpIndex + index"
                    :prop="x.col_id"
                    :label="x.title"
                    align="center"
                    :min-width="130"
                    :sortable="x.sortable ? true : false"
                  >
                    <template #default="scope">
                      <el-tooltip
                        hide-after="50"
                        :content="`${$t('t-detail-by')}
                          ${
                            generalKH.tableData.data[scope.$index][
                              generalKH.tableData.columns.findIndex(
                                (i) => i.is_identity
                              )
                            ]
                          } 
                          ${$t('t-and-service')}
                          ${
                            generalKH.listNameService.filter(
                              (i) => i.id === x.service_id
                            )[0].name
                          }`"
                        effect="dark"
                      >
                        <div @click="handleUser(scope, x)">
                          {{
                            checkDataDatetime(
                              grp,
                              generalKH.tableData.data[scope.$index][
                                generalKH.tableData.columns.findIndex(
                                  (col) => col.col_id == x.col_id
                                )
                              ]
                            )
                          }}
                        </div>
                      </el-tooltip>
                    </template>
                  </el-table-column>
                </template>

                <template #default="scope" v-if="!grp.service_id">
                  <div>
                    {{
                      checkDataDatetime(
                        grp,
                        generalKH.tableData.data[scope.$index][
                          generalKH.tableData.columns.findIndex(
                            (col) => col.col_id == grp.col_id
                          )
                        ]
                      )
                    }}
                  </div>
                </template>
              </el-table-column>

              <el-table-column
                :label="$t('t-action')"
                width="120"
                align="center"
              >
                <template #default="scope">
                  <el-tooltip
                    transition=""
                    hide-after="50"
                    :content="` ${$t('t-view-detail-dashboard')}`"
                    effect="dark"
                  >
                    <button
                      type="button"
                      class="btn btn-soft-info waves-effect waves-light btn-sm"
                      @click="handleClick('dashboard', scope)"
                      style="margin-right: 10px"
                    >
                      <i class="ri-eye-line"></i>
                    </button>
                  </el-tooltip>
                  <el-tooltip
                    transition=""
                    hide-after="50"
                    :content="` ${$t('t-view-dashboard-timespan')}`"
                    effect="dark"
                  >
                    <button
                      type="button"
                      class="btn btn-soft-warning waves-effect waves-light btn-sm"
                      @click="handleClick('dashboard-timespan', scope)"
                    >
                      <i class="mdi mdi-timetable"></i>
                    </button>
                  </el-tooltip>
                </template>
              </el-table-column>
            </el-table>
            <div class="mt-3" style="float: right">
              <el-pagination
                small
                v-model:currentPage="tableRules.page"
                v-model:page-size="tableRules.limit"
                :page-sizes="tableRules.lengthMenu"
                background
                layout="total, sizes, prev, pager, next, jumper"
                :total="generalKH.tableData.total"
                @size-change="fn_tableSizeChange"
                @current-change="fn_tableCurentChange"
                @prev-click="fn_tablePrevClick"
                @next-click="fn_tableNextClick"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.main-body {
  margin: 10px 20px;
}
.main-body-search {
  margin-bottom: 10px;
  width: 400px;
}
.main-body-checkbox {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.checkbox-content {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #dcdfe6;
  border-radius: 5px;
  padding: 0 5px;
  margin-bottom: 10px;
}
.el-checkbox {
  margin-bottom: 0;
  margin-right: 25px;
}
.button-update {
  cursor: pointer;
}
.icon-download {
  font-size: 20px;
}
.el-table {
  height: calc(100vh - 295px);
}
.card-header-table {
  background-color: #fff;
  padding: 18px;
}
::v-deep.el-table th.el-table__cell {
  background-color: #f3f6f9 !important;
}
::v-deep .el-table.is-scrolling-left th.el-table-fixed-column--left {
  background-color: #f3f6f9;
}
</style>

<template>
  <div :id="'cem-card-item-inner-' + props.item.id" class="network-card">
    <div v-if="dataTable.data.length > 0" class="w-100">
      <div class="d-none">{{ A2 }}</div>
      <div class="network">
        <div class="block1">
          <div class="d-flex">
            <div class="block1-item block-device text-center">
              <span>UE</span>
              <div class="block-devices">
                <img
                  src="@/assets/images/network-devices.svg"
                  alt="network devices"
                />
              </div>
            </div>
            <div class="block-modem">
              <span>Modem</span>
              <div class="block-modems">
                <div>
                  <img src="@/assets/images/router.svg" alt="modem" />
                </div>
              </div>
            </div>
            <div class="block1-item block1-item-position block-capturing">
              <div>
                <p>CAPTURING</p>
                <img src="@/assets/images/node.svg" alt="" />
              </div>
            </div>
          </div>

          <div class="block1__info">
            <div class="block1__data">
              {{ data0ne }}
            </div>
            <div class="block1__line"></div>
          </div>
        </div>

        <div class="block2 block2-internet">
          <div class="d-flex justify-content-between align-items-center">
            <div class="block2-item position-relative">
              <div class="block-bras">
                <span>BRAS</span>
                <div class="block2-item position-relative">
                  <img src="@/assets/images/internet.svg" alt="" />
                </div>
              </div>
            </div>
            <div class="block2-item block2-devices segments-text">
              <div
                class="block-devices-server"
                :class="{
                  isDisplayFlex: dataTable.urlIcon?.length > 0,
                  isTextCenter: dataTable.urlIcon?.length == 0,
                }"
              >
                <img
                  src="@/assets/images/server.svg"
                  class="server-img"
                  alt="server"
                />
                <div
                  class="d-flex flex-column"
                  v-if="dataTable.urlIcon?.length > 0"
                >
                  <div
                    class="icons-service"
                    v-for="imgUrl in dataTable.urlIcon"
                    :key="imgUrl.id"
                  >
                    <img
                      :src="imgUrl"
                      alt="server"
                      height="30"
                      width="30"
                      style="margin: 5px"
                    />
                  </div>
                </div>
              </div>
              <p class="segments" v-if="dataTable.nameServer?.length > 0">
                Server {{ dataTable.nameServer }}
              </p>
            </div>
          </div>
          <div class="block2__info">
            <div class="block2__data">
              {{ dataTwo }}
            </div>
            <div class="block2__line"></div>
          </div>
        </div>

        <div class="block3">
          <div class="block1-item block-device text-center">
            <span>UE</span>
            <div class="block-devices">
              <img
                src="@/assets/images/network-devices.svg"
                alt="network devices"
              />
            </div>
          </div>
          <div class="block3__info">
            <div class="block3__data">
              {{ dataThree }}
            </div>
            <div class="block3__line"></div>
          </div>
        </div>
        <div class="line-bg"></div>
      </div>
    </div>

    <div v-else></div>
  </div>
</template>

<script setup>
import { defineProps, onMounted, reactive, watch, ref, computed } from "vue";

import mushroom, { Card_item } from "cem-probe-api";
import { GridStack } from "gridstack";

const props = defineProps({
  item: Card_item,
  itemData: null,
  grid: GridStack,
  dataImageUpdate: Array,
});

const dataTable = reactive({
  data: [],
  columns: [],
  nameServer: "",
  numServiceX: [],
  numServiceY: [],
  urlIcon: [],
});
const data0ne = ref("");
const dataTwo = ref("");
const dataThree = ref("");
const isErrNetwork2s = ref(false);

watch(
  () => props.itemData,
  (newData) => {
    dataTable.numServiceX = [];
    dataTable.numServiceY = [];
    dataTable.data = newData.data;
    dataTable.numServiceX.push({
      title: dataTable.data[0][0],
      value: dataTable.data[0][0],
    });
    dataTable.numServiceY.push({
      title: dataTable.data[0][1],
      value: dataTable.data[0][1],
    });
    data0ne.value = dataTable.data[0][0];
    dataTwo.value = dataTable.data[0][1];
    dataThree.value = dataTable.data[0][2];
  }
);

const A2 = computed(() =>
  props.dataImageUpdate.forEach(async (item) => {
    dataTable.urlIcon = [];
    const response = await mushroom.service.findByIdAsync({
      id: item,
      fields: "name,avatar_id",
    });
    dataTable.nameServer = response.result?.name;
    if (response.result?.avatar_id?.length > 0) {
      const imgSrc = mushroom.$file.linkBuilder.thumb
        .id(response.result.avatar_id)
        .build();
      dataTable.urlIcon.push(imgSrc);
    }
  })
);
onMounted(async () => {
  if (props.item.type === "err-network-2segments") {
    isErrNetwork2s.value = true;
  } else isErrNetwork2s.value = false;
  props.item.network.service_ids.forEach(async (item) => {
    const response = await mushroom.service.findByIdAsync({
      id: item,
      fields: "name,avatar_id",
    });
    dataTable.nameServer = response.result?.name;
    if (response.result?.avatar_id?.length > 0) {
      const imgSrc = mushroom.$file.linkBuilder.thumb
        .id(response.result.avatar_id)
        .build();
      dataTable.urlIcon.push(imgSrc);
    }
  });
});
</script>
<style scoped lang="scss">
.network {
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 1;
  width: 100%;
  padding-bottom: 49px;
}
.block-internet {
  position: absolute;
  left: 45%;
  top: 16px;
}
.block2-internet::after {
  right: 70px;
  margin-bottom: -35px;
}
.block2-internet::before {
  display: none;
}
.block1 {
  min-width: 421px;
  flex-direction: column;
  &__data {
    color: red;
  }
  &__info {
    margin-top: 13px;
    text-align: center;
  }
  &__line {
    border-bottom: thin solid #a6c1ff;
    width: calc(100% - 100px);
    margin-left: 50px;
    position: relative;
    &::after {
      content: "\eb7c";
      position: absolute;
      font-family: "remixicon", sans-serif;
      font-size: 10px;
      color: #a6c1ff;
      z-index: 1;
      left: -6px;
      top: -7px;
    }
    &::before {
      content: "\eb7c";
      position: absolute;
      font-family: "remixicon", sans-serif;
      font-size: 10px;
      color: #a6c1ff;
      z-index: 1;
      right: -6px;
      top: -7px;
    }
  }
  &::after {
    margin-bottom: -35px;
  }
}
.block2 {
  min-width: calc(100% - 421px - 170px);
  &__data {
    color: red;
    width: calc(100% - 99px);
  }
  &__info {
    text-align: center;
    margin-top: 55px;
  }
  &__line {
    border-bottom: thin solid #a6c1ff;
    width: calc(100% - 23px);
    margin-left: -48px;
    position: relative;
    &::after {
      content: "\eb7c";
      position: absolute;
      font-family: "remixicon", sans-serif;
      font-size: 10px;
      color: #a6c1ff;
      z-index: 1;
      left: -6px;
      top: -7px;
    }
    &::before {
      content: "\eb7c";
      position: absolute;
      font-family: "remixicon", sans-serif;
      font-size: 10px;
      color: #a6c1ff;
      z-index: 1;
      right: -5px;
      top: -7px;
    }
  }
}
.block3 {
  min-width: 170px;
  display: flex;
  justify-content: flex-end;
  &__data {
    color: red;
    width: calc(100% - 34px);
  }
  img {
    width: 107px;
  }
  &__info {
    text-align: center;
    position: absolute;
    width: 264px;
    bottom: 14px;
  }
  &__line {
    border-bottom: thin solid #a6c1ff;
    width: calc(100% - 78px);
    margin-left: 24px;
    position: relative;
    &::after {
      content: "\eb7c";
      position: absolute;
      font-family: "remixicon", sans-serif;
      font-size: 10px;
      color: #a6c1ff;
      z-index: 1;
      left: -5px;
      top: -7px;
    }
    &::before {
      content: "\eb7c";
      position: absolute;
      font-family: "remixicon", sans-serif;
      font-size: 10px;
      color: #a6c1ff;
      z-index: 1;
      right: -5px;
      top: -7px;
    }
  }
  &::after {
    position: absolute;
    content: "";
    border-right: thin dashed #cdcdcd;
    width: calc(100% - 107px);
    height: 100%;
    bottom: 0px;
    z-index: -1;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>

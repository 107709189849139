<script>
import { layoutComputed } from "@/state/helpers";
import mushroom from "cem-probe-api";
import { listMenu } from "../stores/ListMenu";
import { abortAPI } from "@/stores/abortAPI";
import noneIconMenu from "../assets/icon/none-icon-menu.svg";
import MethodService from "../service/MethodService";
import { useRoute } from "vue-router";
import { langWeb } from "../stores/lang";
const storeListMenu = listMenu();
const storeAbortAPI = abortAPI();
export default {
  data() {
    return {
      loadingService: true,
      settings: {
        minScrollbarLength: 60,
      },
      menuAPI: [],
      menuUsecasesAPI: [],
      roles: "",
      primaryLink: localStorage.urlPrimary,
      isHaveConfigMenuServiceId: false,
      srcIconMenuConfigService: null,
      nameMenuConfigService: null,
      idMenuConfigService: null,
      listMenuConfigService: [],
      listMenu: [],
      route: useRoute(),
      lang_web: langWeb(),
      pid: localStorage.pid,
    };
  },
  computed: {
    ...layoutComputed,
    layoutType: {
      get() {
        return this.$store ? this.$store.state.layout.layoutType : {};
      },
    },
  },

  watch: {
    $route: {
      handler: "onRoutechange",
      immediate: true,
      deep: true,
    },
    "lang_web.showLang": {
      handler: "onChangeLang",
      immediate: true,
      deep: true,
    },
  },

  // async created() {
  //   this.menuAPI = storeListMenu.getListMenuService();
  //   this.menuUsecasesAPI = storeListMenu.getListMenuUsecases();
  // },

  async mounted() {
    this.getMenu();
    const response = JSON.parse(localStorage.getItem("userInfo"));
    this.roles = response.result.roles;
    await storeListMenu.getListMenuService();
    await storeListMenu.getListMenuUsecases();
    this.menuAPI = storeListMenu.showListMenuService;
    this.menuUsecasesAPI = storeListMenu.showListMenuUsecases;
    // lấy cấu hình xem có menu service id không
    const resCauHinhMenuService = await mushroom.system_config.listAsync({
      fields: "id,key,value,is_public",
      filters: this.roles.includes("Admin")
        ? ["key=menu_service_id", "is_public=true"]
        : ["key=menu_service_id"],
    });
    if (resCauHinhMenuService.result?.length > 0) {
      this.idMenuConfigService = resCauHinhMenuService.result[0].value;
      this.isHaveConfigMenuServiceId = true;
      const arrayIds = resCauHinhMenuService.result[0].value.split(",");
      const resMenuConfigService = arrayIds.map((x) =>
        mushroom.service.findByIdAsync({
          id: x,
        })
      );
      await Promise.allSettled(resMenuConfigService).then((data) => {
        data?.forEach((item) => {
          if (item.status === "fulfilled") {
            this.listMenuConfigService.push({
              name: item.value.result.name || item.value.result.fullname,
              id: item.value.result.id,
              srcAvatar: item.value.result.avatar_id
                ? mushroom.$file.linkBuilder.thumb
                    .id(item.value.result.avatar_id)
                    .clip(20, 20, "center")
                    .build()
                : null,
            });
          }
        });
      });
    } else {
      this.isHaveConfigMenuServiceId = false;
      this.srcIconMenuConfigService = null;
    }
    if (document.querySelectorAll(".navbar-nav .collapse")) {
      let collapses = document.querySelectorAll(".navbar-nav .collapse");
      collapses.forEach((collapse) => {
        // Hide sibling collapses on `show.bs.collapse`
        collapse.addEventListener("show.bs.collapse", (e) => {
          e.stopPropagation();
          let closestCollapse = collapse.parentElement.closest(".collapse");
          if (closestCollapse) {
            let siblingCollapses =
              closestCollapse.querySelectorAll(".collapse");
            siblingCollapses.forEach((siblingCollapse) => {
              if (siblingCollapse.classList.contains("show")) {
                let aChild = siblingCollapse.parentNode.firstChild;
                if (aChild.hasAttribute("aria-expanded")) {
                  aChild.setAttribute("aria-expanded", "false");
                }
                siblingCollapse.classList.remove("show");
              }
            });
          } else {
            let getSiblings = (elem) => {
              // Setup siblings array and get the first sibling
              let siblings = [];
              let sibling = elem.parentNode.firstChild;
              // Loop through each sibling and push to the array
              while (sibling) {
                if (sibling.nodeType === 1 && sibling !== elem) {
                  siblings.push(sibling);
                }
                sibling = sibling.nextSibling;
              }
              return siblings;
            };
            let siblings = getSiblings(collapse.parentElement);
            siblings.forEach((item) => {
              if (item.childNodes.length > 2)
                item.firstElementChild.setAttribute("aria-expanded", "false");
              let ids = item.querySelectorAll("*[id]");
              ids.forEach((item1) => {
                // let aChild = item1.parentNode.firstChild;
                // if (aChild?.hasAttribute("aria-expanded")) {
                //   aChild.setAttribute("aria-expanded", "false");
                // }
                item1.classList.remove("show");
                if (item1.childNodes.length > 2) {
                  let val = item1.querySelectorAll("ul li a");

                  val.forEach((subitem) => {
                    if (subitem.hasAttribute("aria-expanded"))
                      subitem.setAttribute("aria-expanded", "false");
                  });
                }
              });
            });
          }
        });

        // Hide nested collapses on `hide.bs.collapse`
        collapse.addEventListener("hide.bs.collapse", (e) => {
          e.stopPropagation();
          let childCollapses = collapse.querySelectorAll(".collapse");
          childCollapses.forEach((childCollapse) => {
            let childCollapseInstance = childCollapse;
            childCollapseInstance.hide();
          });
        });
      });
    }
  },

  methods: {
    onRoutechange(ele) {
      this.initActiveMenu(ele.path);
      if (document.getElementsByClassName("mm-active").length > 0) {
        const currentPosition =
          document.getElementsByClassName("mm-active")[0].offsetTop;
        if (currentPosition > 500)
          if (this.$refs.isSimplebar)
            this.$refs.isSimplebar.value.getScrollElement().scrollTop =
              currentPosition + 300;
      }
      this.menuAPI = storeListMenu.showListMenuService;
      this.menuUsecasesAPI = storeListMenu.showListMenuUsecases;
    },

    initActiveMenu(ele) {
      setTimeout(() => {
        const menuTree = this.listMenu.find(
          (menu) =>
            menu.children.length > 0 &&
            menu.children.map((item) => item.link).includes(ele)
        );

        if (document.querySelector("#navbar-nav")) {
          let a = document
            .querySelector("#navbar-nav")
            .querySelector('[href="#a' + menuTree?.id + '"]');
          if (a) {
            let b = document
              .querySelector("#navbar-nav")
              .querySelector('[href="#a' + menuTree?.id + '"] + div');
            a.classList.add("active");
            if (b) {
              b.classList.add("show");
              b.parentElement.children[0].classList.add("active");
              b.parentElement.children[0].setAttribute("aria-expanded", "true");
              if (b.parentElement.closest(".collapse.menu-dropdown")) {
                b.parentElement.closest(".collapse").classList.add("show");
                if (b.parentElement.closest(".collapse").previousElementSibling)
                  b.parentElement
                    .closest(".collapse")
                    .previousElementSibling.classList.add("active");
              }
            }
          }
        }
      });
    },
    sanitizedLinkPrimary() {
      return MethodService.purifyUrl(this.primaryLink + "menu/list-menu");
    },
    sanitizedLinkMenu(textLink) {
      return MethodService.purifyUrl("#a" + textLink);
    },
    async getMenu() {
      const type = localStorage.type_login;
      try {
        const response = await mushroom.menu.views.get_treeAsync(
          {
            domains: ["primary", type],
            currentDomain: type,
          },
          { clientCache: true }
        );
        this.listMenu = response.result;
        this.initActiveMenu(window.location.pathname);
      } catch (e) {
        console.error("Có lỗi: %o", e);
      }
    },

    setLink(link) {
      if (link === "#") return "/";
      return link;
    },

    setLinkPrimary(link) {
      const textLink =
        link === "#" ? this.primaryLink : this.primaryLink + link.slice(1);
      return MethodService.purifyUrl(textLink);
    },

    setIcon(iconId) {
      if (iconId) return MethodService.thumbIcon(iconId, 32);
      return noneIconMenu;
    },

    changeMenu() {
      storeAbortAPI.deleteAbort();
    },
    async logViewMenu(id, link, target) {
      if (target === "primary") {
        try {
          await mushroom.menu.log_view_menuAsync({
            body: {
              menu_id: id,
              probe_id: this.pid,
            },
          });
        } catch (error) {
          console.error("Có lỗi: %o", error);
        }
        window.location.href = this.setLinkPrimary(link);
      } else {
        mushroom.menu.log_view_menuAsync({
          body: {
            menu_id: id,
            probe_id: this.pid,
          },
        });
        this.$router.push(link);
      }
    },
  },
};
</script>

<template>
  <div class="container-fluid">
    <ul class="navbar-nav h-100" id="navbar-nav">
      <!-- menu động -->
      <li class="nav-item" v-for="menu in listMenu" :key="menu.id">
        <template v-if="menu.children.length > 0">
          <a
            class="nav-link menu-link"
            :href="sanitizedLinkMenu(menu.id)"
            data-bs-toggle="collapse"
            role="button"
            aria-expanded="false"
            aria-controls="sidebarMultilevel"
          >
            <img
              :src="setIcon(menu?.icon_id)"
              alt="icon"
              style="width: 18px; margin-right: 10px"
            />
            <span>{{
              menu?.label_lang?.[this.lang_web.showLang] &&
              menu.label_lang?.[this.lang_web.showLang].trim() !== ""
                ? menu.label_lang?.[this.lang_web.showLang]
                : menu.label
            }}</span>
          </a>
          <div class="collapse menu-dropdown" :id="`a${menu.id}`">
            <ul class="nav nav-sm flex-column">
              <li
                class="nav-item"
                v-for="item in menu.children"
                :key="item.id"
                @click="changeMenu()"
              >
                <template v-if="item.target_domain === 'primary'">
                  <a
                    class="nav-link"
                    :href="setLinkPrimary(item.link)"
                    data-key=""
                    @click.prevent="
                      logViewMenu(item.id, item.link, item.target_domain)
                    "
                  >
                    <img
                      :src="setIcon(item?.icon_id)"
                      alt="icon"
                      style="width: 18px; margin-right: 10px"
                    />
                    <span>{{
                      item?.label_lang?.[this.lang_web.showLang] &&
                      item.label_lang?.[this.lang_web.showLang].trim() !== ""
                        ? item.label_lang?.[this.lang_web.showLang]
                        : item?.label
                    }}</span>
                  </a>
                </template>
                <template v-else>
                  <router-link
                    :to="setLink(item.link)"
                    class="nav-link"
                    @click.prevent="logViewMenu(item.id, item.link)"
                  >
                    <img
                      :src="setIcon(item?.icon_id)"
                      alt="icon"
                      style="width: 18px; margin-right: 10px"
                    />
                    <span>{{
                      item?.label_lang?.[this.lang_web.showLang] &&
                      item.label_lang?.[this.lang_web.showLang].trim() !== ""
                        ? item.label_lang?.[this.lang_web.showLang]
                        : item?.label
                    }}</span>
                  </router-link>
                </template>
              </li>
            </ul>
          </div>
        </template>
        <template v-else>
          <template v-if="menu.target_domain === 'primary'">
            <a
              :href="setLinkPrimary(menu.link)"
              class="nav-link menu-link"
              data-key=""
              @click.prevent="
                logViewMenu(menu.id, menu.link, menu.target_domain)
              "
            >
              <img
                :src="setIcon(menu?.icon_id)"
                alt="icon"
                style="width: 18px; margin-right: 10px"
              />
              <span>{{
                menu?.label_lang?.[this.lang_web.showLang] &&
                menu.label_lang?.[this.lang_web.showLang].trim() !== ""
                  ? menu.label_lang?.[this.lang_web.showLang]
                  : menu.label
              }}</span>
            </a>
          </template>
          <template v-else>
            <router-link
              @click="changeMenu()"
              :to="menu.link"
              class="nav-link menu-link"
              :data-key="menu.id"
              @click.prevent="logViewMenu(menu.id, menu.link)"
            >
              <img
                :src="setIcon(menu?.icon_id)"
                alt="icon"
                style="width: 18px; margin-right: 10px"
              />
              <span>{{
                menu?.label_lang?.[this.lang_web.showLang] &&
                menu.label_lang?.[this.lang_web.showLang].trim() !== ""
                  ? menu.label_lang?.[this.lang_web.showLang]
                  : menu.label
              }}</span>
            </router-link>
          </template>
        </template>
      </li>

      <li class="nav-item" v-if="this.roles.includes('Admin')">
        <a
          class="nav-link menu-link"
          :href="sanitizedLinkPrimary()"
          data-key=""
          @click.prevent="
            logViewMenu('ConfigMenu', '/menu/list-menu', 'primary')
          "
        >
          <i class="ri-menu-add-line"></i>
          <span>{{ $t("t-config-menu") }}</span>
        </a>
      </li>
    </ul>
  </div>
  <!-- Sidebar -->
</template>
<style scoped>
.loading-container {
  height: 200px;
}
li {
  width: 100%;
}
span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
</style>

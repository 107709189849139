<script setup>
// layout -> bắt buộc
import Layout from "../../layouts/main.vue";
import mushroom from "cem-probe-api";
import { onMounted, ref } from "vue";
import { UploadCloudIcon } from "@zhuowenli/vue-feather-icons";
import MethodService from "../../service/MethodService";
import toastr from "toastr";

// const source1 = "/hdsd-test.pdf";
let checkFile = ref(false);
let idFile = ref("");
let urlFile = ref("");
// const fileHuongDan = reactive({ value: {} });

const getHuongDan = async () => {
  try {
    const response = await mushroom.system_config.listAsync({
      filters: "key=user_manual",
    });
    if (response.result.length > 0) {
      checkFile.value = true;
      idFile.value = response.result[0].id;
      urlFile.value = mushroom.$file.linkBuilder.thumb
        .id(response.result[0].value)
        .build();
    }
    console.log("Các system_config thỏa mãn: %o, info: %o", response);
  } catch (e) {
    console.error("Có lỗi: %o", e);
  }
};

const createFile = async (value) => {
  if (checkFile.value) {
    const system_config_object = {
      id: idFile.value, // required
      value: value,
    };
    try {
      const response = await mushroom.system_config.partialUpdateAsync(
        system_config_object
      );
      if (response.result == idFile.value) {
        console.log("Cập nhật thành công");
        getHuongDan();
      } else console.log("Dữ liệu không thay đổi");
    } catch (error) {
      console.error("Có lỗi: %o", error);
    }
  } else {
    const system_config_object = {
      key: "user_manual",
      value: value,
      is_public: true,
      note: value,
    };
    try {
      const newId = await mushroom.system_config.createAsync(
        system_config_object
      );
      console.log("Thêm mới thành công, new id: %o", newId);
      getHuongDan();
    } catch (error) {
      console.error("Có lỗi: %o", error);
    }
  }
};

const uploadHuongDan = async () => {
  try {
    let nameFile = document.getElementById("uploadFile").files[0]?.name;
    let typeFile = nameFile?.slice(nameFile.indexOf(".")).toLowerCase();
    if (typeFile) {
      if (typeFile !== ".pdf") {
        toastr.warning("Vui lòng chọn đúng định dạng file pdf");
      } else {
        let res = await mushroom.$file.uploadAsync({
          file: document.getElementById("uploadFile").files[0],
        });
        if (res.result) {
          console.log("res.result", res.result);
          createFile(res.result);
        }
      }
    }
  } catch (e) {
    console.log(e);
    MethodService.showError(e.code);
  }
};

onMounted(() => {
  getHuongDan();
});
</script>

<template>
  <Layout>
    <div class="card" style="min-height: 100vh">
      <div
        class="card-header"
        style="display: flex; justify-content: space-between"
      >
        <h3 class="text-uppercase mb-0">Hướng dẫn sử dụng</h3>
        <div class="text-end" style="display: none">
          <el-tooltip content="Tải lên" placement="bottom" effect="light">
            <div class="image-btn">
              <button
                class="btn btn-secondary bg-gradient btn-border waves-effect waves-light"
                style="width: 90px; padding: 8px 0 0 0"
                type="submit"
              >
                <label for="uploadFile">
                  <upload-cloud-icon
                    size="1.3x"
                    class="custom-class"
                    style="font-size: 17px"
                  ></upload-cloud-icon>
                </label>
              </button>
              <input
                type="file"
                id="uploadFile"
                style="display: none"
                @change="uploadHuongDan"
              />
            </div>
          </el-tooltip>
        </div>
      </div>
      <div class="card-body" style="padding: 0 0 15px 0">
        <iframe
          :src="urlFile"
          style="width: 100%; height: 90vh"
          title="Guide"
        ></iframe>
      </div>
    </div>
  </Layout>
</template>

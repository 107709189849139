<template>
  <div :id="'cem-card-item-inner-' + props.item.id" style="width: 100%">
    <div v-if="dataTable?.data?.length > 0" class="group-text">
      <div class="table-group">
        <div
          class="table-group__row"
          v-for="row in dataTable.data"
          :key="row.id"
        >
          <p class="textData textData__left">
            {{ row[0] }}
          </p>
          <p class="textData textData__right">
            {{ renderData(row[1]) }}
          </p>
        </div>
      </div>
    </div>
    <div v-else class="table-group__nodata">
      <div class="text-center">
        <i
          style="font-size: 40px; opacity: 0.5; color: #e0ba00"
          class="ri-information-line"
        ></i>
        <p>
          {{ $t("t-no-data") }}
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, reactive, watch } from "vue";

import { Card_item } from "cem-probe-api";
import { GridStack } from "gridstack";

const props = defineProps({
  item: Card_item,
  itemData: null,
  grid: GridStack,
});

const dataTable = reactive({ data: [], columns: [] });
const renderData = (data) => {
  if (!data || data === null || data === undefined || data === "") {
    return "-";
  } else return data;
};
watch(
  () => props.itemData,
  (newData) => {
    dataTable.data = newData.data;
  }
);
</script>
<style scoped lang="scss">
.table-group {
  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px dashed #d9e5ff;
  }
  &__nodata {
    height: 100%;
    display: grid;
    place-content: center;
  }
}
.textData {
  font-size: 14px;
  color: #606266;
  font-weight: normal;
  margin: 8px 5px;
  &__left {
    text-align: left;
  }
  &__right {
    text-align: right;
  }
}
.group-text {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>

<template>
  <div :id="'cem-card-item-' + props.itemData.id" class="grid-stack-item">
    <div class="grid-stack-item-content" style="overflow: hidden">
      <div
        class="card-header d-flex justify-content-between card-header-45"
        :style="`background-color: ${backgroundColorTitle} `"
        :class="{
          hideCardHeader:
            is_hiddenCardHeader && storeChangeEdit.showEdit === false,
        }"
      >
        <div class="divider">
          <div class="d-flex align-items-center divider_title">
            <div class="title-card-items" :style="`color: ${colorTitle} `">
              {{ titleCardItems }}
            </div>
            <div
              class="btn-group-action-title"
              v-if="showEditTile && storeChangeEdit.showEdit === true"
            >
              <div
                class="action-input-btn button-delete-title"
                :title="`${$t('t-delete-title-card-items')}`"
                @click="backOldTitleCardItems"
              >
                <i class="ri-close-line"></i>
              </div>
              <div
                class="action-input-btn button-save-title"
                :title="`${$t('t-save-title-card-items')}`"
                @click="saveTitleCardItems(props.itemData.id)"
              >
                <i class="ri-save-line"></i>
              </div>
            </div>
          </div>
          <div
            class="download-card-items"
            v-if="storeChangeEdit.showEdit === false"
            @click="downloadInfoCardItem(props.itemData.title)"
          >
            <span>{{ $t("t-download") }}</span>
            <img src="../assets/icon/download.svg" alt="download-card" />
          </div>
          <div
            class="d-flex align-items-center card-items-btn"
            v-if="storeChangeEdit.showEdit === true"
          >
            <div
              class="action action-update button-update"
              :title="`${$t('t-update')}`"
              @click="showUpdateCardItems"
            >
              <i
                class="ri-settings-4-line"
                :style="`color: ${colorTitle} `"
              ></i>
            </div>

            <div
              class="action action-hide action-close box-btn-close button-action"
              :title="`${$t('t-delete-card')}`"
              @click="removeCardItems(props.itemData.id, props.itemData.title)"
            >
              <i
                class="ri-delete-bin-line"
                :style="`color: ${colorTitle} `"
              ></i>
            </div>
            <!-- :class="{
              hideActionDeleteCardItem: props.actionDeleteOption,
            }" -->
          </div>
        </div>
      </div>
      <div
        class="card-body card-body-item"
        :class="{
          isDisplayFlex: state.isPaging,
          isTableComponent: tableComponent,
          notHeader: is_hiddenCardHeader && storeChangeEdit.showEdit === false,
        }"
      >
        <chart-stack-bar-vert
          v-if="props.itemData.type == 'chart-stack-bar-vert'"
          :item="props.itemData"
          :itemData="cardItemData.itemData"
          :key="keyStackBarVert"
          :dataChartOption="dataChartOption"
          @showDialogDashboardPopup="showDialogDashboardPopup"
        />
        <chart-stack-bar-hori
          v-if="props.itemData.type == 'chart-stack-bar-hori'"
          :item="props.itemData"
          :itemData="cardItemData.itemData"
          :key="keyStackBarHori"
          :dataChartOption="dataChartOption"
          @showDialogDashboardPopup="showDialogDashboardPopup"
        />
        <single-vertical-bar-chart
          v-if="props.itemData.type == 'chart-bar-vert-single'"
          :item="props.itemData"
          :itemData="cardItemData.itemData"
          :key="keyBarVertSingle"
          :dataChartOption="dataChartOption"
          @showDialogDashboardPopup="showDialogDashboardPopup"
        />
        <single-hori-bar-chart
          v-if="props.itemData.type == 'chart-bar-hori-single'"
          :item="props.itemData"
          :itemData="cardItemData.itemData"
          :key="keyBarHoriSingle"
          :dataChartOption="dataChartOption"
          @showDialogDashboardPopup="showDialogDashboardPopup"
        />
        <single-line-chart
          v-if="props.itemData.type == 'chart-line-single'"
          :item="props.itemData"
          :itemData="cardItemData.itemData"
          :key="keyLineSingle"
          :dataChartOption="dataChartOption"
        />
        <single-line-chart-area
          v-if="props.itemData.type == 'chart-line-area-single'"
          :item="props.itemData"
          :itemData="cardItemData.itemData"
          :key="keyLineAreaSingle"
          :dataChartOption="dataChartOption"
        />
        <multi-vertical-bar-chart
          v-if="props.itemData.type == 'chart-bar-vert-multi'"
          :item="props.itemData"
          :itemData="cardItemData.itemData"
          :key="keyBarVertMulti"
          :dataChartOption="dataChartOption"
          @showDialogDashboardPopup="showDialogDashboardPopup"
        />
        <multi-hori-bar-chart
          v-if="props.itemData.type == 'chart-bar-hori-multi'"
          :item="props.itemData"
          :itemData="cardItemData.itemData"
          :key="keyBarHoriMulti"
          :dataChartOption="dataChartOption"
          @showDialogDashboardPopup="showDialogDashboardPopup"
        />
        <multi-line-chart
          v-if="props.itemData.type == 'chart-line-multi'"
          :item="props.itemData"
          :itemData="cardItemData.itemData"
          :key="keyLineMulti"
          :dataChartOption="dataChartOption"
        />
        <multi-line-chart-area
          v-if="props.itemData.type == 'chart-line-area-multi'"
          :item="props.itemData"
          :itemData="cardItemData.itemData"
          :key="keyLineAreaMulti"
          :dataChartOption="dataChartOption"
        />
        <pie-chart
          v-if="props.itemData.type == 'chart-pie'"
          :item="props.itemData"
          :itemData="cardItemData.itemData"
          :key="keyPie"
          :dataChartOption="dataChartOption"
          @showDialogDashboardPopup="showDialogDashboardPopup"
        />
        <radar-chart
          v-if="props.itemData.type == 'chart-radar'"
          :item="props.itemData"
          :itemData="cardItemData.itemData"
          :key="keyRadar"
          :dataChartOption="dataChartOption"
        />
        <table-chart
          v-if="props.itemData.type == 'table'"
          :item="props.itemData"
          :itemData="cardItemData.itemData"
          :isLoading="cardItemData.loading"
          :heightCardContent="props.heightCardContent"
          :configTable="cardItemData.configTable"
          :configPaging="configPaging"
          @getDataTableWhenSort="getDataTableWhenSort"
          :key="keyTable"
          @showDialogDashboardPopup="showDialogDashboardPopup"
        />
        <group-text
          v-if="props.itemData.type == 'group-text'"
          :item="props.itemData"
          :itemData="cardItemData.itemData"
          :key="keyGroupText"
        />
        <err-network-2s
          v-if="props.itemData.type == 'err-network-2segments'"
          :item="props.itemData"
          :itemData="cardItemData.itemData"
          :key="keyNetwork2segments"
          :dataImageUpdate="dataImageUpdate"
        />
        <err-network-3s
          v-if="props.itemData.type == 'err-network-3segments'"
          :item="props.itemData"
          :itemData="cardItemData.itemData"
          :key="keyNetwork3segments"
          :dataImageUpdate="dataImageUpdate"
        />
        <stats-network
          v-if="props.itemData.type == 'stats-network-multi-serv'"
          :item="props.itemData"
          :itemData="cardItemData.itemData"
          :key="keyStatsNetwork"
          :dataImageUpdate="dataImageUpdate"
        />
        <time-line
          v-if="props.itemData.type == 'time-line'"
          :item="props.itemData"
          :itemData="cardItemData.itemData"
          :key="keyTimeLine"
        />
        <table-screen-dashboard
          v-if="props.itemData.type == 'table-screen'"
          :item="props.itemData"
          :itemData="cardItemData.itemData"
          :itemTableRules="tableRules"
          :showEdit="storeChangeEdit.showEdit"
          @filterData="filterData"
          @columnsOnScreen="getColumnsOnScreen"
          @showDialogDashboardPopup="showDialogDashboardPopup"
          :key="keyTableScreen"
        />

        <div
          v-if="state.ui.loadingState === 'loading'"
          class="error-data-card-items"
        >
          <div class="text-center">
            <i
              style="font-size: 40px; opacity: 0.5; color: #0f6a33"
              class="bx bx-loader-alt loading-icon"
            ></i>
            <p>{{ $t("t-loading-get-data") }}</p>
          </div>
        </div>

        <div
          v-if="state.ui.loadingState === 'nodata'"
          class="error-data-card-items"
        >
          <div class="text-center">
            <i
              style="font-size: 40px; opacity: 0.5; color: #e0ba00"
              class="ri-information-line"
            ></i>
            <p>
              {{ $t("t-no-data") }}
            </p>
          </div>
        </div>

        <div
          v-if="state.ui.loadingState === 'error'"
          class="error-data-card-items"
        >
          <div class="text-center">
            <i
              style="font-size: 40px; opacity: 0.5; color: #ff0000"
              class="ri-error-warning-line"
            ></i>
            <p>
              {{ $t("t-have-error-get-data") }}
            </p>
          </div>
        </div>
        <div
          style="float: right"
          v-if="state.isPaging == true && state.ui.loadingState == 'rendered'"
        >
          <el-pagination
            small
            background
            class="mt-1"
            layout="total, sizes, prev, pager, next, jumper"
            v-model:currentPage="tableRules.page"
            v-model:page-size="tableRules.limit"
            :page-sizes="tableRules.lengthMenu"
            :total="totalTable"
            @size-change="fn_tableSizeChange"
            @current-change="fn_tableCurentChange"
          />
        </div>
      </div>
    </div>
  </div>

  <card-item-config
    :idCardItem="idCardItem"
    :cardIdToCardItemsConfig="props.itemData.card_id"
    :cardTitleToCardItemsConfig="props.cardTitle"
    :showCardItemConfig="state.ui.cardItemConfig.visible"
    :stepNumberForCardItem="state.ui.cardItemConfig.step"
    :typeShowCardItemConfig="state.ui.cardItemConfig.type"
    @closeDialogCardItemsConfig="closeDialogCardItemsConfig"
    @addNewCardItems="addNewCardItems"
    @updateNewCardItems="updateNewCardItems"
    @dataChartConfig="dataChartConfig"
  ></card-item-config>

  <DialogDashboardPopup
    :idPopupDashboard="idDashboardPopup"
    :isShowDialog="isShowDialog"
    :headerOptions="headerOptions"
    @closeDialog="closeDialogDashboardPopup"
  />
  <DialogDashboardPopup />
</template>

<script setup>
import {
  defineEmits,
  defineProps,
  onMounted,
  reactive,
  ref,
  watch,
  toRaw,
} from "vue";
import mushroom, { Card_item } from "cem-probe-api";
import { GridStack } from "gridstack";
import MethodService from "@/service/MethodService";
import toastr from "toastr";
import Swal from "sweetalert2";
import { useRoute } from "vue-router";
const router = useRoute();
import { datePicker } from "../stores/datePicker";
const storeDatePicker = datePicker();
import { timePicker } from "@/stores/timePicker";
const storeTimePicker = timePicker();
import { changeEdit } from "@/stores/changeEdit";
const storeChangeEdit = changeEdit();
const emit = defineEmits([""]);
import { useI18n } from "vue-i18n";
const { t } = useI18n();

import CardItemConfig from "./CardItemsConfig/CardItemsConfig.vue";
import SingleVerticalBarChart from "./card-items/SingleVerticalBarChartComponent";
import SingleHoriBarChart from "./card-items/SingleHoriBarChartComponent";
import RadarChart from "./card-items/RadarChart.vue";
import SingleLineChart from "./card-items/SingleLineChartComponent";
import MultiVerticalBarChart from "./card-items/MultiVerticalBarChartComponent";
import MultiHoriBarChart from "./card-items/MultiHoriBarChartComponent";
import MultiLineChart from "./card-items/MultiLineChartComponent";
import SingleLineChartArea from "./card-items/SingleLineChartAreaComponent.vue";
import MultiLineChartArea from "./card-items/MultiLineChartAreaComponent.vue";
import TableChart from "./card-items/TableComponent.vue";
import GroupText from "./card-items/GroupTextComponent.vue";
import ErrNetwork2s from "./card-items/ErrNetwork2sComponent.vue";
import ErrNetwork3s from "./card-items/ErrNetwork3sComponent.vue";
import StatsNetwork from "./card-items/StatsNetworkComponent.vue";
import PieChart from "./card-items/PieChartComponent.vue";
import TimeLine from "./card-items/TimeLineComponent.vue";
import TableScreenDashboard from "./card-items/TableScreenDashboard.vue";
import ChartStackBarVert from "./card-items/ChartStackBarVert.vue";
import ChartStackBarHori from "./card-items/ChartStackBarHori.vue";
import { abortAPI } from "@/stores/abortAPI";
import DialogDashboardPopup from "./DialogDashboardPopup.vue";

const keyRadar = ref(0);
const keyStackBarVert = ref(0);
const keyStackBarHori = ref(0);
const keyBarVertSingle = ref(0);
const keyBarHoriSingle = ref(0);
const keyLineSingle = ref(0);
const keyLineAreaSingle = ref(0);
const keyBarVertMulti = ref(0);
const keyBarHoriMulti = ref(0);
const keyLineMulti = ref(0);
const keyLineAreaMulti = ref(0);
const keyPie = ref(0);
const keyTable = ref(0);
const keyTableScreen = ref(0);
const keyGroupText = ref(0);
const keyNetwork2segments = ref(0);
const keyNetwork3segments = ref(0);
const keyStatsNetwork = ref(0);
const keyTimeLine = ref(0);
const dataImageUpdate = ref([]);
const dataUpdateCardItem = ref(null);
const dataChartConfigRender = ref();
const dataChartOption = ref();
const totalTable = ref(0);
const startDate = ref(null);
const stopDate = ref(null);
const tableComponent = ref(false);
const configPaging = ref(false);
const fullDataCardItem = ref([]);
const abortController = new AbortController();
const storeAbortAPI = abortAPI();
const idDashboardPopup = ref("");
const isShowDialog = ref(false);
const headerOptions = ref({ is_hidden: true }); // thông tin header ẩn truyền vào dialog dashboard popup
const arrayColsFromListColumnsName = ref([]);

/*
- không truyền idCardItem = props.itemData.id luôn vì trong CardItemConfig đang watch idCardItem
- state.ui.cardItemConfig.type và step không điền luôn như bên dashboardCardComponent vì trong CardItemConfig
cũng đang watch
*/
const idCardItem = ref("");
const showEditTile = ref(false);
// const showEditTileCardItems = () => {
//   showEditTile.value = true;
// };
const props = defineProps({
  itemData: Card_item,
  cardTitle: String,
  grid: GridStack,
  countProp: String,
  actionDeleteOption: Boolean,
  heightCardContent: Number,
});
const colorTitle = ref("#1c1c1c");
const backgroundColorTitle = ref("#fff");
const is_hiddenCardHeader = ref(false);
const configTitle = ref({});
const showDialogDashboardPopup = (id, replaceUrl) => {
  if (!id) return;
  idDashboardPopup.value = id;
  if (replaceUrl) {
    window.history.pushState(null, null, replaceUrl);
  }

  isShowDialog.value = true;
};
const closeDialogDashboardPopup = () => {
  isShowDialog.value = false;
  window.history.pushState(null, null, localStorage.getItem("urlOld"));
};
const getDataTableWhenSort = async (data) => {
  let order = {};
  let key = data.sort;
  let value = data.sort_type == "desc" ? -1 : 1;
  key ? (order[key] = value) : (order = {});
  await getDatasourceAsync(
    storeDatePicker.showDatePicker,
    router.name === "DichVuTheoKhungGio"
      ? storeTimePicker.showTimePicker
      : null,
    tableRules.limit,
    tableRules.offset,
    order
  );
};

const getColumnsOnScreen = (cols) =>
  (arrayColsFromListColumnsName.value = cols || []);
const getArrayColumnsForExportExcel = async () => {
  let arrayColumns = [];
  let rawColumns = toRaw(fullDataCardItem.value?.columns) || [];

  if (arrayColsFromListColumnsName.value.length > 0) {
    rawColumns.forEach((rawCol) => {
      arrayColsFromListColumnsName.value.forEach((col) => {
        if (col === rawCol.field_name) arrayColumns.push(rawCol);
      });
    });
  } else {
    try {
      const response = await mushroom.card_item.findByIdAsync({
        id: fullDataCardItem.value?.card_item_id,
        fields: "table",
      });
      if (response.result?.table?.format?.length > 0) {
        rawColumns.forEach((rawCol) => {
          response.result.table.format.forEach((col) => {
            if (rawCol.code === col.code) rawCol.visible = col.visible;
          });
        });
        arrayColumns = rawColumns.filter((col) => col.visible);
      } else arrayColumns = rawColumns;
    } catch (e) {
      console.error(e);
    }
  }

  return arrayColumns;
};
const getDataForExportExcel = (arrayColumns) => {
  let datas = [];
  let rawDatas = fullDataCardItem.value?.data || [];
  let fullRawColums = toRaw(fullDataCardItem.value?.columns);
  let fullData = [];
  rawDatas.forEach((raw) => {
    let obj = {};
    raw.forEach((item, index) => {
      if (!fullRawColums[index]) return;
      obj[fullRawColums[index].field_name] = item;
    });
    fullData.push(obj);
  });

  fullData.forEach((data, index) => {
    let obj1 = {};
    arrayColumns.forEach((col) => {
      if (data[col.field_name]) {
        let text = fullData[index][col.field_name];
        if (col.formatString?.includes(":markdown")) {
          text = text
            ?.replace(/[#~*`]/g, "")
            .replace(/\\;/g, ";")
            .replace(/<\/?\w{1,255}[^>/]{0,1000}\/?>/g, "");
        }
        obj1[col.title] = text;
      }
    });
    datas.push(obj1);
  });

  return datas;
};

const getInfoCardItems = async (cardId) => {
  try {
    const response = await mushroom.card_item.findByIdAsync(
      {
        id: cardId,
      },
      {
        enabledCache: false,
      }
    );

    if (response?.result?.table?.format) {
      return response.result.table.format;
    }
  } catch (e) {
    console.error("Có lỗi: %o", e);
    toastr.error(t("t-have-error-get-data"));
  }
};

const downloadInfoCardItem = async (cardTitle) => {
  bodyDataForDownloadCardItem.value.limit = null;
  bodyDataForDownloadCardItem.value.offset = null;
  const response = await mushroom.probe_data.getDatasourceAsync({
    body: bodyDataForDownloadCardItem.value,
    settings: {
      abortController,
    },
  });
  if (response.result) {
    response.result.card_item_id =
      bodyDataForDownloadCardItem.value.card_item_id;
    fullDataCardItem.value = response.result;

    //Khởi tại giá trị objectDatas chứa dữ liệu dưới dạng object thay vì array
    let objectDatasArr = [];
    for (let i = 0; i < response.result.data.length; i++) {
      let objectData = {};
      for (let j = 0; j < response.result.columns.length; j++) {
        objectData[response.result.columns[j].field_name] =
          response.result.data[i][j];
      }
      objectDatasArr.push(objectData);
    }
    fullDataCardItem.value.objectDatas = objectDatasArr;
  } else fullDataCardItem.value = [];
  if (!fullDataCardItem.value || fullDataCardItem.value.length === 0) {
    toastr.warning(t("t_no_data_download"));
    return;
  }

  let columnsFormat = await getInfoCardItems(idCardItem.value);

  //Check xem item này có phải dạng table screen
  if (columnsFormat?.length !== 0) {
    let tmpColumesCardItem = MethodService.copyObject(cardItemData.configTable);
    let tmpdataObjectCardItem = MethodService.copyObject(
      fullDataCardItem.value.objectDatas
    );

    //Khởi tạo lại mảng column header theo thứ tự hiển thị
    let orderedColumns = [];
    columnsFormat.forEach((item1) => {
      tmpColumesCardItem.forEach((item2) => {
        if (item1.field_name === item2.field_name) {
          item2.title = item1.title_name || item1.field_name;
          orderedColumns.push(item2);
        }
      });
    });
    // Khởi tạo lại array Data theo thứ tự hiển thị
    let orderedDataArrs = [];
    tmpdataObjectCardItem.forEach((item1) => {
      let orderedDataArr = [];
      orderedColumns.forEach((item2) => {
        orderedDataArr.push(item1[item2.field_name] ?? "");
      });
      orderedDataArrs.push(orderedDataArr);
    });

    fullDataCardItem.value.columns = orderedColumns;
    fullDataCardItem.value.data = orderedDataArrs;
  }

  let arrayColumnsForExportExcel = [];
  let arrayDataForExportExcel = [];

  arrayColumnsForExportExcel = await getArrayColumnsForExportExcel();
  arrayDataForExportExcel = getDataForExportExcel(arrayColumnsForExportExcel);
  MethodService.exportExcel(
    arrayColumnsForExportExcel,
    arrayDataForExportExcel,
    cardTitle
  );
};

const cardItemData = reactive({
  itemData: null,
  configTable: [],
  loading: false,
});
const bodyDataForPaginationTable = ref({});
const bodyDataForDownloadCardItem = ref({});

const titleCardItems = ref("");
const titleOldCardItems = ref("");

const state = reactive({
  ui: {
    loadingState: "loading",
    cardItemConfig: {
      visible: false,
      type: "",
      step: 0,
    },
  },
  isPaging: false,
});

const backOldTitleCardItems = () => {
  titleCardItems.value = titleOldCardItems.value;
  showEditTile.value = false;
};

const showUpdateCardItems = () => {
  idCardItem.value = props.itemData.id;
  state.ui.cardItemConfig.visible = true;
  state.ui.cardItemConfig.step = 2;
  state.ui.cardItemConfig.type = "update";
};

const removeCardItems = async (idCardItem, titleCardItem) => {
  Swal.fire({
    title: t("t-warning"),
    html: `${t("t_delete_card_item")} <strong>${titleCardItem}</strong>`,
    icon: "question",
    confirmButtonColor: "#f46a6a",
    confirmButtonText: t("swal_accept"),
    cancelButtonText: t("swal_cancel"),
    showCancelButton: true,
    allowOutsideClick: false,
  }).then(async (result) => {
    if (result.isConfirmed) {
      try {
        const response = await mushroom.card_item.deleteAsync({
          id: idCardItem,
        });
        if (response.result == 1)
          toastr.success(t("toastr_delete_card_item_success"));
        const domRemove = document.getElementById(
          `cem-card-item-${idCardItem}`
        );
        props.grid.removeWidget(domRemove);
        emit("reloadCardItems");
      } catch (e) {
        console.error("Có lỗi: %o", e);
        MethodService.showError(e.code);
      }
    }
  });
};

const saveTitleCardItems = async (idCardItem) => {
  emit("reloadingData");
  const card_item_object = {
    id: idCardItem,
    title: titleCardItems.value.trim(),
  };
  if (card_item_object.title.length > 50) {
    toastr.warning(t("toastr_title_length_required"));
  } else if (card_item_object.title?.trim() == "") {
    toastr.warning(t('"toastr_title_not_all_space"'));
    document.querySelector(`[gs-id="${idCardItem}"] input`).value = " ";
  } else {
    try {
      const response = await mushroom.card_item.partialUpdateAsync(
        card_item_object
      );
      if (response.result == idCardItem) {
        titleCardItems.value = card_item_object.title;
        toastr.success(t("t_update_card_item_title_success"));
      } else t("t_update_card_item_title_success");
    } catch (e) {
      console.error("Có lỗi: %o", e);
      MethodService.showError(e.code);
    } finally {
      emit("unloadingData");
      showEditTile.value = false;
    }
  }
  emit("unloadingData");
};
const dataChartConfig = (data) => {
  dataChartConfigRender.value = data;
};
const updateNewCardItems = async (data) => {
  data.table.format.forEach((item) => {
    if (!item?.showCellLink) item.cellLink = null;
    else {
      if (!item.cellLink.defaultLink?.formatString)
        item.cellLink.defaultLink = null;
    }
  });

  let arrSqlTableFormart = [];
  if (data.sql_table_formats?.length > 0) {
    data.sql_table_formats.includes("\n")
      ? (arrSqlTableFormart = data.sql_table_formats.split("\n"))
      : (arrSqlTableFormart = data.sql_table_formats.split());
  } else arrSqlTableFormart = [];

  let arrChartSqlTableFormart = [];
  if (data.chart.alert.sql_table_formats?.length > 0) {
    data.chart.alert.sql_table_formats.includes("\n")
      ? (arrChartSqlTableFormart =
          data.chart.alert.sql_table_formats.split("\n"))
      : (arrChartSqlTableFormart = data.chart.alert.sql_table_formats.split());
  } else arrChartSqlTableFormart = [];

  dataUpdateCardItem.value = data;
  dataImageUpdate.value = data.network.service_ids;
  const card_item_object = {
    id: props.itemData.id,
    card_id: data.card_id,
    title: data.title,
    general: {
      header: {
        is_hidden: data.general.header.is_hidden,
        color: data.general.header.color,
        backgroundColor: data.general.header.backgroundColor,
      },
    },
    table_config_id: data.table_config_id,
    table: {
      hasNo: data.table.hasNo,
      isPaging: data.table.isPaging,
      format: data.table.format,
    },
    chart: {
      title: {
        show: data.chart.title.show,
        text: data.chart.title.text,
        textStyle: {
          fontSize: data.chart.title.textStyle.fontSize,
          color: data.chart.title.textStyle.color,
        },
      },
      legend: {
        show: data.chart.legend.show,
        orient: data.chart.legend.orient,
        align: {
          horizontal: data.chart.legend.align.horizontal || "center",
          vertical: data.chart.legend.align.vertical || "bottom",
        },
      },
      x_axis: {
        min: data.chart.x_axis.min,
        max: data.chart.x_axis.max,
      },
      y_axis: {
        min: data.chart.y_axis.min,
        max: data.chart.y_axis.max,
        axisLabel: {
          rotate: data.chart.y_axis.axisLabel?.rotate || null,
        },
      },
      alert: {
        sql: data.chart.alert.sql,
        sql_table_formats: arrChartSqlTableFormart,
        table_config_id: data.chart.alert.table_config_id,
      },
      borderRadiusType: data.chart.borderRadiusType,
      smooth: data.chart.smooth,
      chart_link: data.chart.chart_link,
      multi_color_for_single_bar_chart:
        data.chart.multi_color_for_single_bar_chart,
      data_serials: data.chart.data_serials,
      color: data.chart.color,
    },
    network: {
      service_ids: data.network.service_ids,
    },
    sql: data.sql,
    sql_table_formats: arrSqlTableFormart,
    settings: {
      add_start_days: data.settings?.add_start_days ?? null,
      add_end_days: data.settings?.add_end_days ?? null,
    },
  };
  configTitle.value = card_item_object.chart.title;
  dataChartConfigRender.value = card_item_object.chart.color;
  dataChartOption.value = card_item_object.chart;
  configPaging.value = card_item_object.table.isPaging;
  try {
    const response = await mushroom.card_item.partialUpdateAsync(
      card_item_object
    );
    if (response.result == props.itemData.id) {
      backgroundColorTitle.value =
        card_item_object.general.header.backgroundColor;
      colorTitle.value = card_item_object.general.header.color;
      is_hiddenCardHeader.value = card_item_object.general.header.is_hidden;
      state.isPaging = card_item_object.table.isPaging;
      if (data.type == "chart-bar-vert-single") {
        cardItemData.itemData = [];
        keyBarVertSingle.value += 1;
      }
      if (data.type == "chart-bar-hori-single") {
        cardItemData.itemData = [];
        keyBarHoriSingle.value += 1;
      }
      if (data.type == "chart-line-single") {
        cardItemData.itemData = [];
        keyLineSingle.value += 1;
      }
      if (data.type == "chart-line-area-single") {
        cardItemData.itemData = [];
        keyLineAreaSingle.value += 1;
      }
      if (data.type == "chart-bar-vert-multi") {
        cardItemData.itemData = [];
        keyBarVertMulti.value += 1;
      }
      if (data.type == "chart-bar-hori-multi") {
        cardItemData.itemData = [];
        keyBarHoriMulti.value += 1;
      }

      if (data.type == "chart-line-multi") {
        cardItemData.itemData = [];
        keyLineMulti.value += 1;
      }
      if (data.type == "chart-line-area-multi") {
        cardItemData.itemData = [];
        keyLineAreaMulti.value += 1;
      }
      if (data.type == "chart-pie") {
        cardItemData.itemData = [];
        keyPie.value += 1;
      }
      if (data.type == "chart-radar") {
        cardItemData.itemData = [];
        keyRadar.value += 1;
      }
      if (data.type == "chart-stack-bar-vert") {
        cardItemData.itemData = [];
        keyStackBarVert.value += 1;
      }
      if (data.type == "chart-stack-bar-hori") {
        cardItemData.itemData = [];
        keyStackBarHori.value += 1;
      }
      if (data.type == "table") {
        cardItemData.itemData = [];
        cardItemData.configTable = [];
        keyTable.value += 1;
      }
      if (data.type == "table-screen") {
        emit("uploadCardItem");
        keyTableScreen.value += 1;
      }
      if (data.type == "time-line") {
        cardItemData.itemData = [];
        keyTimeLine.value += 1;
      }
      if (data.type == "group-text") {
        cardItemData.itemData = [];
        keyGroupText.value += 1;
      }
      if (data.type == "err-network-2segments") {
        cardItemData.itemData = [];
        keyNetwork2segments.value += 1;
      }
      if (data.type == "err-network-3segments") {
        cardItemData.itemData = [];
        keyNetwork3segments.value += 1;
      }
      if (data.type == "stats-network-multi-serv") {
        //cardItemData.itemData = [];
        keyStatsNetwork.value += 1;
      }

      state.ui.loadingState = "loading";
      state.ui.cardItemConfig.step = "";
      state.ui.cardItemConfig.type = "";
      titleCardItems.value = data.title;

      await getDatasourceAsync(
        storeDatePicker.showDatePicker,
        router.name === "DichVuTheoKhungGio"
          ? storeTimePicker.showTimePicker
          : null,
        tableRules.limit,
        tableRules.offset
      );
      toastr.success(t("t_update_card_item_title_success"));
    } else toastr.warning(t("t_update_card_item_title_success"));
  } catch (e) {
    console.error("Có lỗi: %o", e);
    MethodService.showError(e.code);
  } finally {
    state.ui.cardItemConfig.visible = false;
  }
};
const closeDialogCardItemsConfig = () => {
  state.ui.cardItemConfig.visible = false;
  state.ui.cardItemConfig.step = "";
};
const countCardItemHaveData = ref(0);
const getDatasourceAsync = async (
  date,
  time,
  limit,
  offset,
  sort,
  searchKey,
  searchValue
) => {
  setTimeout(async () => {
    let bodyData = {
      card_item_id: props.itemData.id,
      probe_id: localStorage.pid,
      filters: {},
      extra: {
        customer: localStorage.customer ? localStorage.customer : "",
      },
      order: sort || null,
      offset: offset,
    };

    if (searchKey && searchValue) {
      bodyData.filters[searchKey] = searchValue.trim();
    }
    if (state.isPaging) {
      bodyData.limit = limit;
      bodyData.offset = offset;
    } else {
      bodyData.limit = null;
      bodyData.offset = null;
    }

    const urlParams = window.location.search.slice(1);
    const searchParams = new URLSearchParams(urlParams);
    startDate.value = searchParams.get("start_date");
    stopDate.value = searchParams.get("stop_date");
    if (searchParams.get("customer")?.length > 0)
      delete bodyData.extra.customer;
    for (const [key, value] of searchParams) {
      if (
        key !== "start_date" &&
        key !== "stop_date" &&
        key !== "serviceID" &&
        key !== "$colfilter"
      )
        bodyData.extra[key] = value;
    }

    if (startDate.value?.length > 0 && stopDate.value?.length > 0) {
      bodyData.start_date = new Date(startDate.value).toISOString();
      bodyData.stop_date = new Date(stopDate.value).toISOString();
    } else if (localStorage.getItem("dateSelect")) {
      let dates = localStorage.getItem("dateSelect");
      let d = dates.split(",");
      bodyData.start_date = new Date(d[0]);
      bodyData.stop_date = new Date(d[1]);
    } else {
      bodyData.start_date = date[0];
      bodyData.stop_date = date[1];
    }

    if (time) {
      bodyData.extra.start_hour = time[0];
      bodyData.extra.end_hour = time[1];
    }
    bodyDataForPaginationTable.value = bodyData;
    bodyDataForDownloadCardItem.value = bodyData;

    try {
      const res = await mushroom.probe_data.getDatasourceAsync(
        {
          body: bodyData,
          settings: {
            abortController,
          },
        },
        { enabledCache: true }
      );
      // test marker
      if (res.result.alert) {
        res.result.alert.data = res.result.alert.data.map((item) => {
          if (!item.bgcolor) item.bgcolor = "rgba(255, 173, 177, 0.4)";
          if (!item.textcolor) item.textcolor = "#000";
          return item;
        });
      }

      cardItemData.itemData = [];
      if (res.result && res.result.data.length > 0) {
        countCardItemHaveData.value += 1;
        state.ui.loadingState = "rendered";
        cardItemData.itemData = res.result;
        totalTable.value = res.result.total;
        cardItemData.configTable = dataUpdateCardItem.value
          ? dataUpdateCardItem.value.table.format
          : props.itemData?.table?.format;
        if (
          cardItemData.itemData.data.length < 6 &&
          props.itemData.type == "table"
        )
          tableComponent.value = true;
      } else {
        state.ui.loadingState = "nodata";
        countCardItemHaveData.value += 0;
      }
      if (countCardItemHaveData.value == 0) {
        emit(
          "countCardItemHaveData",
          countCardItemHaveData,
          props.itemData.card_id
        );
      }
    } catch (error) {
      console.error(error);
      state.ui.loadingState = "error";
      cardItemData.itemData = [];
    }
  }, 500);
};

const tableRules = reactive({
  lengthMenu: [10, 20, 50, 100],
  allowPaging: true,
  allowSorting: true,
  allowSelect: false,
  showFormSearch: false,
  showUrl: true,
  total: 0,
  page: 1,
  limit: 20,
  offset: 0,
});
// phân trang
const fn_tableSizeChange = (limit) => {
  tableRules.limit = limit;
  fn_tableChangeOffset(tableRules.page);
};
const fn_tableCurentChange = (page) => {
  fn_tableChangeOffset(page);
};
const fn_tableChangeOffset = async (page) => {
  cardItemData.loading = true;
  tableRules.page = page;
  tableRules.offset = (tableRules.page - 1) * tableRules.limit;
  if (state.isPaging) {
    bodyDataForPaginationTable.value.limit = tableRules.limit;
    bodyDataForPaginationTable.value.offset = tableRules.offset;
  } else {
    bodyDataForPaginationTable.value.limit = null;
    bodyDataForPaginationTable.value.offset = null;
  }
  try {
    const res = await mushroom.probe_data.getDatasourceAsync(
      {
        body: bodyDataForPaginationTable.value,
        settings: {
          abortController,
        },
      },
      { enabledCache: false }
    );
    if (res.result && res.result.data.length > 0) {
      cardItemData.itemData = [];
      state.ui.loadingState = "rendered";
      cardItemData.itemData = res.result;
    } else state.ui.loadingState = "nodata";
  } catch (error) {
    console.error("lỗi getDatasourceAsync: ", error);
    state.ui.loadingState = "error";
  } finally {
    cardItemData.loading = false;
  }
};

const filterData = async (sort, key, value, type) => {
  if (type === "filter") {
    tableRules.page = 1;
    tableRules.offset = 0;
  }
  await getDatasourceAsync(
    storeDatePicker.showDatePicker,
    router.name === "DichVuTheoKhungGio"
      ? storeTimePicker.showTimePicker
      : null,
    tableRules.limit,
    tableRules.offset,
    sort,
    key,
    value
  );
};

// watch(
//   () => storeDatePicker.showDatePicker,
//   async (newDate) => {
//     if (!newDate) return;

//     cardItemData.itemData = [];
//     state.ui.loadingState = "loading";

//     await getDatasourceAsync(
//       newDate,
//       router.name === "DichVuTheoKhungGio"
//         ? storeTimePicker.showTimePicker
//         : null,
//       tableRules.limit,
//       tableRules.offset
//     );
//     // render lại các card items
//     keyStackBarVert.value += 1;
//     keyStackBarHori.value += 1;
//     keyBarVertSingle.value += 1;
//     keyBarHoriSingle.value += 1;
//     keyLineSingle.value += 1;
//     keyLineAreaSingle.value += 1;
//     keyBarVertMulti.value += 1;
//     keyBarHoriMulti.value += 1;
//     keyLineMulti.value += 1;
//     keyLineAreaMulti.value += 1;
//     keyPie.value += 1;
//     keyRadar.value += 1;
//     keyGroupText.value += 1;
//     keyNetwork2segments.value += 1;
//     keyNetwork3segments.value += 1;
//     keyStatsNetwork.value += 1;
//     keyTimeLine.value += 1;
//     keyTable.value += 1;
//     keyTableScreen.value += 1;
//   }
// );

watch(
  () => storeTimePicker.showTimePicker,
  async (newTime) => {
    if (!newTime) return;
    cardItemData.itemData = [];
    state.ui.loadingState = "loading";
    await getDatasourceAsync(
      storeDatePicker.showDatePicker,
      newTime,
      tableRules.limit,
      tableRules.offset
    );
    keyStackBarVert.value += 1;
    keyStackBarHori.value += 1;
    keyBarVertSingle.value += 1;
    keyBarHoriSingle.value += 1;
    keyLineSingle.value += 1;
    keyLineAreaSingle.value += 1;
    keyBarVertMulti.value += 1;
    keyBarHoriMulti.value += 1;
    keyLineMulti.value += 1;
    keyLineAreaMulti.value += 1;
    keyPie.value += 1;
    keyRadar.value += 1;
    keyGroupText.value += 1;
    keyNetwork2segments.value += 1;
    keyNetwork3segments.value += 1;
    keyStatsNetwork.value += 1;
    keyTimeLine.value += 1;
    keyTable.value += 1;
    keyTableScreen.value += 1;
  }
);

onMounted(async () => {
  storeAbortAPI.setAbort(abortController);

  idCardItem.value = props.itemData.id;
  colorTitle.value = props.itemData.general?.header.color || "#1c1c1c";
  is_hiddenCardHeader.value = props.itemData.general?.header.is_hidden || false;
  backgroundColorTitle.value =
    props.itemData.general?.header.backgroundColor || "#fff";
  configTitle.value = props.itemData.chart?.title || null;
  state.isPaging = props.itemData.table?.isPaging;
  props.grid.addWidget(
    document.getElementById("cem-card-item-" + props.itemData.id),
    {
      id: props.itemData.id,
      x: props.itemData.x,
      y: props.itemData.y,
      w: props.itemData.width,
      h: props.itemData.height,
      noMove: storeChangeEdit.showEdit ? false : true,
      noResize: storeChangeEdit.showEdit ? false : true,
    }
  );

  if (storeChangeEdit.showEdit) {
    props.grid.on("change", async function (e, items) {
      let width = document.body.clientWidth;
      if (width > 768) {
        items.forEach(async function (item) {
          const response = await mushroom.card_item.findByIdAsync({
            id: item.id,
          });
          if (response.result) {
            if (response.result.type == "chart-stack-bar-vert")
              keyStackBarVert.value += 1;
            if (response.result.type == "chart-stack-bar-hori")
              keyStackBarHori.value += 1;
            if (response.result.type == "chart-bar-vert-single")
              keyBarVertSingle.value += 1;
            if (response.result.type == "chart-bar-hori-single")
              keyBarHoriSingle.value += 1;
            if (response.result.type == "chart-line-single")
              keyLineSingle.value += 1;
            if (response.result.type == "chart-line-area-single")
              keyLineAreaSingle.value += 1;
            if (response.result.type == "chart-bar-vert-multi")
              keyBarVertMulti.value += 1;
            if (response.result.type == "chart-bar-hori-multi")
              keyBarHoriMulti.value += 1;
            if (response.result.type == "chart-line-multi")
              keyLineMulti.value += 1;
            if (response.result.type == "chart-line-area-multi")
              keyLineAreaMulti.value += 1;
            if (response.result.type == "chart-pie") keyPie.value += 1;
            if (response.result.type == "chart-radar") keyRadar.value += 1;

            // lỗi: nếu cho render lại thì bị trắng card-items (dự đoán: do không liên quan đến getDataSource)
            // if (response.result.type == "group-text") keyGroupText.value += 1;
            // if (response.result.type == "err-network-2segments")
            //   keyNetwork2segments.value += 1;
            // if (response.result.type == "err-network-3segments")
            //   keyNetwork3segments.value += 1;
            // if (response.result.type == "stats-network-multi-serv")
            //   keyStatsNetwork.value += 1;
            // if (response.result.type == "time-line") keyTimeLine.value += 1;
            //if (response.result.type == "table") keyTable.value += 1;
          }
        });
      }
    });
  }

  titleCardItems.value = props.itemData.title;
  titleOldCardItems.value = props.itemData.title;
  if (props.itemData.table)
    configPaging.value = props.itemData.table.isPaging || false;

  let keySearch = "";
  let valueSearch = "";
  if (router.query.$colfilter) {
    let strFilterURL = router.query.$colfilter;
    let index = strFilterURL.toString().indexOf(":");
    keySearch = strFilterURL.toString().slice(0, index);
    valueSearch = strFilterURL.toString().slice(index + 1);
  }
  await getDatasourceAsync(
    storeDatePicker.showDatePicker,
    router.name === "DichVuTheoKhungGio"
      ? storeTimePicker.showTimePicker
      : null,
    tableRules.limit,
    tableRules.offset,
    null,
    keySearch,
    valueSearch
  );
});
</script>
<style scoped>
.divider {
  border-bottom: thin solid #d9e5ff;
  display: flex;
  padding: 17px 0;
  width: 100%;
  height: 100%;
}

.divider_title {
  width: 100%;
}

.title-card-items {
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-header {
  padding: 0 24px;
  background-color: #fff;
  position: relative;
  z-index: 999;
}

.title-card-items {
  color: #1c1c1c;
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
}

.card-body-item {
  display: flex;
  position: relative;
  width: 100%;
  height: calc(100% - 45px);
  padding: 12px 24px 12px;
}

.isTableComponent {
  align-items: center;
}

.height100 {
  height: 100%;
}

.isDisplayFlex {
  display: block;
  height: calc(100% - 71px) !important;
}

.notHeader {
  height: calc(100% - 26px) !important;
}

.isCardPagination {
  height: 80vh !important;
}

@keyframes xoayvong {
  from {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
  }
}

.loading-icon {
  animation: xoayvong 2s linear 0s infinite;
}

.download-card-items {
  display: flex;
  min-width: 88px;
  cursor: pointer;
  align-items: center;
}

.download-card-items span {
  font-size: 14px;
  line-height: 20px;
  color: #a4a4a4;
  min-width: 70px;
}

.download-card-items img {
  margin-left: 5.33px;
}

::v-deep .el-input__wrapper {
  background-color: transparent;
}
</style>

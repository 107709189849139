<!-- eslint-disable no-unused-vars -->
<script setup>
import mushroom from "cem-probe-api";
import { onMounted, ref, reactive, watch } from "vue";
import Layout from "@/layouts/main.vue";
import ArrowLineLef2 from "@/assets/icon/ArrowLineLeft2.png";
import VLAN from "@/assets/icon/icon-service-health/vlan.png";
import { FormInstance } from "element-plus";
import ServiceHealthItem from "./service-health-item.vue";
import ValidService from "@/service/ValidService";
import { useRoute, useRouter } from "vue-router";
import toastr from "toastr";
import MethodService from "@/service/MethodService";
import DataForm from "./dataFormServiceHealth";
import { ListService } from "@/stores/ListService";
import { datePicker } from "@/stores/datePicker";
const storeDatePicker = datePicker();
import { selectVlan } from "@/stores/changeSelectVlan";
const storeSelectVlan = selectVlan();
import { isHaveDataDashboard } from "@/stores/HaveDataDashboard";
import Swal from "sweetalert2";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
import { changeEdit } from "@/stores/changeEdit";
import { abortAPI } from "@/stores/abortAPI";
import { VueDraggableNext } from "vue-draggable-next";
import { Filter } from "mushroomjs";
import en from "element-plus/lib/locale/lang/en";
import vi from "element-plus/lib/locale/lang/vi";
let _ = require("lodash");
const targetDomainsSelect = DataForm.targetDomains;
const targetTypeSelect = DataForm.targetType;
const storeChangeEdit = changeEdit();
const storeAbortAPI = abortAPI();
const storeIsHaveDataDashboard = isHaveDataDashboard();
const loadingData = ref(false);
const storeListService = ListService();
import { listMenu } from "@/stores/ListMenu";
import { langWeb } from "../../stores/lang";
const storeListMenu = listMenu();
const lang_web = langWeb();
const router = useRouter();
const route = useRoute();
const ruleFormAddServiceHealth = ref(FormInstance);
const ruleFormUpdateServiceHealth = ref(FormInstance);
const countDropdown = ref(1);
const reRenderServiceHealthItem = ref(0);
const showDialogAddServiceHealth = ref(false);
const showDialogUpdateServiceHealth = ref(false);
const showDialogSort = ref(false);
const loadingAcceptFormAdd = ref(false);
const loadingAcceptFormUpdate = ref(false);
const valueMode = ref("VLAN : ALL");
const titleServiceHealthForUpdate = ref("");
const idServiceHealth = ref("");
const listServices = ref([]);
const listTableConfigForKPI = ref([]);
const listTableConfigForKPIClone = ref([]);
const listTableConfigForLineChart = ref([]);
const listTableConfigForLineChartClone = ref([]);
const listTableConfigForBarChart = ref([]);
const listTableConfigForBarChartClone = ref([]);
const checkFalse = ref(0);
const idRequestDownload = ref("");
const refScroll = ref(null);
const serviceHealthData = ref([]);
const listServiceHealth = ref([]);
const nameProbe = ref("");
const listVlanIDs = ref([]);
const listServiceHealthKPI = ref([]);
const listKPI = ref([]);
const listDichVu = ref([]);
const listUsecase = ref([]);
const isShowInputSearch = ref(false);
const listTableScreen = ref([]);
const dataLinkParam = reactive({
  value: [
    {
      code: undefined,
      value: undefined,
      action: "add",
    },
  ],
});
const inputSearch = ref(null);
const infoSearch = reactive({ text: "", id: undefined });
const statusSearch = ref("");
const arrayServiceHeathName = ref([]);
const errorCode = ref([]);
const errorValue = ref([]);
const errorCodeRegex = ref([]);
const errorCodeDuplicate = ref([]);
const errorCodeDefault = ref([]);
const listDefaultParam = [
  "id",
  "kpi_id",
  "kpi_code",
  "kpi_value",
  "start_date",
  "stop_date",
  "vlan_id",
  "search_key",
  "limit",
  "offset",
  "filters",
  "fields",
  "sort",
  "showFormSearch",
  "quality_levels",
  "uc_type",
  "page",
  "menu",
  "access_token",
  "pid",
  "urlPrimary",
  "nameProbe",
  "type_login",
];
const listDynamic = ref([]);
const listItem = ref([]);
const listItemScreen = ref([]);
const listItemShow = ref(["", "", "", "", "", "", "", "", "", "", "", ""]);
const totalList = ref(0);
const hidePagiNext = ref(false);
const showIcon = ref(false);
const disableCallApiGetAllServiceHealth = ref(false);
const disableCallChangePage = ref(false);
const abortController = new AbortController();
const isDisabledButtonSearch = ref(false);
const isLoadingSkeleton = ref(true);
let dataFormServiceHealth = ref(MethodService.copyObject(DataForm.dataForm));
let listTypeChart = ref(MethodService.copyObject(DataForm.listTypeChart));
const tableRules = reactive(MethodService.copyObject(DataForm.tableRules));
const checkValidCode = (code, re, index) => {
  if (code && re.test(code)) {
    errorCode.value[index] = undefined;
    errorCodeRegex.value[index] = undefined;
  } else if (code && !re.test(code)) {
    errorCode.value[index] = undefined;
    errorCodeRegex.value[index] = index;
  } else {
    errorCodeRegex.value[index] = undefined;
    errorCode.value[index] = index;
  }
};
const checkValueCode = (code, index) => {
  const listCode = dataLinkParam.value
    .filter((item, count) => item.code && count !== index)
    .map((item) => item.code);
  if (listCode.includes(code)) {
    errorCodeDuplicate.value[index] = index;
  } else {
    errorCodeDuplicate.value[index] = undefined;
  }

  if (listDefaultParam.includes(code)) {
    errorCodeDefault.value[index] = index;
  } else {
    errorCodeDefault.value[index] = undefined;
  }
};
const checkData = (data) => {
  const item = serviceHealthData.value.find((x) => x.id === data.id);
  if (!item) return false;
  if (
    !item.dataChart ||
    (Object.keys(item.dataChart).length > 0 &&
      item.dataChart?.data?.length > 0 &&
      item.dataChart.data.some(
        (x) =>
          x.filter((y) => y && String(y).replace(/\s+/g, "") !== "").length > 0
      ))
  )
    return false;
  if (!item.dataKPI) return false;
  return (
    item.dataKPI.length === 0 ||
    item.dataKPI.every((x) => !x.value || x.value === "NA")
  );
};
const changeParam = (code, value, index, label) => {
  if (label === "code") {
    checkValueCode(code, index);
    const re = /^(\w+)$/;
    checkValidCode(code, re, index);
  }
  if (label === "value") {
    dataLinkParam.value[index].value =
      value && value.trim().length > 0 ? value : undefined;
    errorValue.value[index] = dataLinkParam.value[index].value
      ? undefined
      : index;
  }
};
const addParam = (row, index) => {
  const re = /^(\w+)$/;
  const listCode = dataLinkParam.value
    .filter((item, count) => item.code && count !== index)
    .map((item) => item.code);
  if (
    row.code &&
    row.value &&
    row.value.trim().length > 0 &&
    re.test(row.code) &&
    !listCode.includes(row.code) &&
    !listDefaultParam.includes(row.code)
  ) {
    const length = dataLinkParam.value.length;
    dataLinkParam.value[length - 1] = {
      code: row.code,
      value: row.value,
      action: "",
    };
    dataLinkParam.value.push({
      code: undefined,
      value: undefined,
      action: "add",
    });
  } else {
    checkValueCode(row.code, index);
    checkValidCode(row.code, re, index);
    errorValue.value[index] =
      row.value && row.value.trim().length > 0 ? undefined : index;
  }
};
const deleteParam = (index) => {
  dataLinkParam.value.splice(index, 1);
  errorCode.value[index] = undefined;
  errorValue.value[index] = undefined;
  errorCodeRegex.value[index] = undefined;
  errorCodeDuplicate.value[index] = undefined;
  errorCodeDefault.value[index] = undefined;
};
const validateSqlFormats = (rule, value, callback) => {
  if (!value || value.length === 0) {
    callback();
  } else {
    checkFalse.value = 0;
    const numberOfLineBreaks = (value.toString().match(/\n/g) || []).length;
    if (numberOfLineBreaks >= 1) {
      let stringSQL = value?.split("\n");
      stringSQL.forEach((item) => {
        if (!item.includes("@table_name")) checkFalse.value += 1;
      });
      checkFalse.value > 0
        ? callback(new Error(t("t-place-sql-formats")))
        : callback();
    }
    if (numberOfLineBreaks === 0) {
      checkFalse.value = value.includes("@table_name")
        ? 0
        : (checkFalse.value += 1);

      checkFalse.value > 0
        ? callback(new Error(t("t-place-sql-formats")))
        : callback();
    }
  }
};
const rules = reactive({
  name: [ValidService.required, ValidService.checkSpaceString],
  default_chart_type: [ValidService.requiredChange],
  link_info: {
    target_domain: [ValidService.requiredChange],
    target_type: [ValidService.requiredChange],
    target_id: [ValidService.requiredChange],
    link: [ValidService.checkNoSpace, ValidService.requiredChange],
  },
  line_chart: {
    service_id: [ValidService.required],
    table_config_id: [ValidService.requiredChange],
    sql: [ValidService.required, ValidService.sql],
    sql_table_formats: [{ validator: validateSqlFormats, trigger: "blur" }],
  },
  bar_chart: {
    service_id: [ValidService.required],
    table_config_id: [ValidService.requiredChange],
    sql: [ValidService.required, ValidService.sql],
    sql_table_formats: [{ validator: validateSqlFormats, trigger: "blur" }],
  },
  table: {
    service_id: [ValidService.required],
    table_config_id: [ValidService.requiredChange],
    sql: [ValidService.required, ValidService.sql],
    sql_table_formats: [{ validator: validateSqlFormats, trigger: "blur" }],
  },
});
const getAllService = async () => {
  storeListService.data.forEach((item) => {
    listServices.value.push({
      value: item.id,
      label: item.name,
    });
  });
};
const getListTableConfigForKPI = (id) => {
  dataFormServiceHealth.value.table.table_config_id = undefined;
  if (id) {
    listTableConfigForKPI.value = listTableConfigForKPIClone.value.filter(
      (item) => item.service_id === id
    );
  } else {
    listTableConfigForKPI.value = listTableConfigForKPIClone.value;
  }
};
const getListTableConfigForLineChart = async (id) => {
  dataFormServiceHealth.value.line_chart.table_config_id = undefined;
  if (id) {
    listTableConfigForLineChart.value =
      listTableConfigForLineChartClone.value.filter(
        (item) => item.service_id === id
      );
  } else {
    listTableConfigForLineChart.value = listTableConfigForLineChartClone.value;
  }
};
const getListTableConfigForBarChart = async (id) => {
  dataFormServiceHealth.value.bar_chart.table_config_id = undefined;
  if (id) {
    listTableConfigForBarChart.value =
      listTableConfigForBarChartClone.value.filter(
        (item) => item.service_id === id
      );
  } else {
    listTableConfigForBarChart.value = listTableConfigForBarChartClone.value;
  }
};
const getAllTableConfig = async () => {
  try {
    const response = await mushroom.table_config.getAllAsync(
      {
        filters: Filter.eq("type", localStorage.getItem("type_login")).build(),
      },
      { enabledCache: false }
    );

    listTableConfigForKPI.value = [];
    listTableConfigForLineChart.value = [];
    listTableConfigForBarChart.value = [];
    response.result.forEach((item) => {
      listTableConfigForKPI.value.push({
        value: item.id,
        label: item.name,
        service_id: item.service_id,
      });
      listTableConfigForLineChart.value.push({
        value: item.id,
        label: item.name,
        service_id: item.service_id,
      });
      listTableConfigForBarChart.value.push({
        value: item.id,
        label: item.name,
        service_id: item.service_id,
      });
    });
    listTableConfigForKPIClone.value = [...listTableConfigForKPI.value];
    listTableConfigForLineChartClone.value = [
      ...listTableConfigForLineChart.value,
    ];
    listTableConfigForBarChartClone.value = [
      ...listTableConfigForBarChart.value,
    ];
  } catch (e) {
    console.error("error: %o", e);
  }
};
const getListDichVu = async () => {
  listDichVu.value = [];
  try {
    const responseRoles = await mushroom.$auth.meAsync({
      settings: {
        abortController,
      },
    });
    const response = await mushroom.service.getAllAsync({
      fields: "id,type,name",
      filters: responseRoles.result.roles.includes("Admin")
        ? Filter.ne("disabled", true).ne("is_menu_hidden", true).build()
        : Filter.ne("is_menu_hidden", true).build(),
    });
    if (response.result) {
      if (dataFormServiceHealth.value.link_info.target_domain === "probe") {
        listDichVu.value = response.result.filter(
          (item) => item.type === "probe"
        );
      } else if (
        dataFormServiceHealth.value.link_info.target_domain === "bras"
      ) {
        listDichVu.value = response.result.filter(
          (item) => item.type === "bras"
        );
      } else listDichVu.value = response.result;
      storeListMenu.menuPhanCap.forEach((menuStore) => {
        listDichVu.value.forEach((dichvu) => {
          if (menuStore.targetId === dichvu.id) dichvu.name = menuStore.label;
        });
      });
      listDichVu.value = _.sortBy(listDichVu.value, [
        function (o) {
          return o.name.toLowerCase();
        },
      ]);
    }
  } catch (e) {
    console.error("Có lỗi: %o", e);
  }
};
const getListTableScreen = async () => {
  try {
    const response = await mushroom.table_screen.getAllAsync({
      fields: "id,type,name",
      filters: Filter.eq(
        "type",
        localStorage.getItem("type_login") || "probe"
      ).build(),
    });
    if (response.result) {
      if (dataFormServiceHealth.value.link_info.target_domain === "probe") {
        listTableScreen.value = response.result.filter(
          (item) => item.type === "probe"
        );
      } else if (
        dataFormServiceHealth.value.link_info.target_domain === "bras"
      ) {
        listTableScreen.value = response.result.filter(
          (item) => item.type === "bras"
        );
      } else listTableScreen.value = response.result;
      storeListMenu.menuPhanCap.forEach((menuStore) => {
        listTableScreen.value.forEach((tableScreen) => {
          if (menuStore.targetId === tableScreen.id)
            tableScreen.name = menuStore.label;
        });
      });
      listTableScreen.value = _.sortBy(listTableScreen.value, [
        function (o) {
          return o.name.toLowerCase();
        },
      ]);
    }
  } catch (e) {
    console.error("Có lỗi: %o", e);
  }
};
const getListUsecase = async () => {
  try {
    const response = await mushroom.usecase.getAllAsync({
      fields: "id,name",
    });
    if (response.result) {
      response.result.forEach((item) => {
        listUsecase.value.push({
          value: item.id,
          label: item.name,
        });
      });
      storeListMenu.menuPhanCap.forEach((menuStore) => {
        listUsecase.value.forEach((usecase) => {
          if (menuStore.targetId === usecase.value)
            usecase.label = menuStore.label;
        });
      });
      listUsecase.value = _.sortBy(listUsecase.value, [
        function (o) {
          return o.label.toLowerCase();
        },
      ]);
    }
  } catch (e) {
    console.error("Có lỗi: %o", e);
  }
};
const getListMenu = async (ids) => {
  try {
    const response = await mushroom.menu.getAllAsync({
      filters: Filter.in("id", ids).build(),
    });
    if (!response.result) return;
    const list = listDynamic.value.map((item) => {
      const menu = response.result.find((x) => x.id === item.menu_id);
      if (menu) {
        return {
          id: item.id,
          menu_id: item.menu_id,
          name: menu.label,
        };
      }
    });
    listDynamic.value = list.filter((item) => item);
    storeListMenu.menuPhanCap.forEach((menuStore) => {
      listDynamic.value.forEach((dynamic) => {
        if (menuStore.targetId === dynamic.id) dynamic.name = menuStore.label;
      });
    });
    listDynamic.value = _.sortBy(listDynamic.value, [
      function (o) {
        return o.name.toLowerCase();
      },
    ]);
  } catch (e) {
    console.error("Có lỗi: %o", e);
  }
};
const getListDynamic = async () => {
  try {
    const response = await mushroom.dynamic_dashboard.getAllAsync();
    if (!response.result) return;
    listDynamic.value = response.result;
    const listMenuId = response.result
      .map((item) => item.menu_id)
      .filter((item) => item);
    if (listMenuId.length > 0) await getListMenu(listMenuId);
  } catch (e) {
    console.error("Có lỗi: %o", e);
  }
};
const renderLink = async (type, id) => {
  let link = "";
  if (type === "service") link = "/" + "services/" + id;
  if (type === "table_screen") link = "/" + "table-screen/" + id;
  if (type === "usecase") {
    const response = await mushroom.usecase.findByIdAsync({
      id: id,
      fields: "code",
    });
    if (response.result)
      link = "/" + "usecases/" + response.result.code.toLowerCase() + "/" + id;
  }
  if (type === "dynamic") link = "/" + "dashboard/" + id;
  return link;
};
const submitFormAddSeviceHealth = (formEl) => {
  const name = dataFormServiceHealth.value.name;
  titleServiceHealthForUpdate.value = dataFormServiceHealth.value.name;
  loadingAcceptFormAdd.value = true;

  if (!formEl) return;
  formEl.validate(async (valid) => {
    if (valid) {
      const resForIndex = await mushroom.service_health.listAsync({
        sort: "-index",
        limit: 1,
      });
      const resNewServiceHealth = await mushroom.service_health.createAsync({
        name: name,
        index:
          resForIndex.result && resForIndex.result[0]
            ? resForIndex.result[0].index + 1
            : 1,
      });
      if (resNewServiceHealth.result) {
        idServiceHealth.value = resNewServiceHealth.result;
        const res = await mushroom.service_health.findByIdAsync({
          id: idServiceHealth.value,
        });
        if (!res.result.table)
          res.result.table = {
            sql: undefined,
            sql_table_formats: undefined,
            table_config_id: undefined,
            service_id: undefined,
          };
        if (!res.result.line_chart) {
          res.result.line_chart = {
            sql: undefined,
            sql_table_formats: undefined,
            table_config_id: undefined,
            service_id: undefined,
          };
        }
        if (!res.result.bar_chart) {
          res.result.bar_chart = {
            sql: undefined,
            sql_table_formats: undefined,
            table_config_id: undefined,
            service_id: undefined,
          };
        }
        if (!res.result.link_info) {
          res.result.link_info = {
            target_domain: undefined,
            target_type: undefined,
            target_id: undefined,
            link: undefined,
          };
        }
        res.result.option_link = false;
        dataFormServiceHealth.value = res.result;
        if (listItemScreen.value.length >= tableRules.limit) {
          await getAllListServiceHealth();
        } else {
          await getAllServiceHealth();
        }

        setTimeout(() => {
          loadingAcceptFormAdd.value = false;
          showDialogAddServiceHealth.value = false;
        }, 1000);
        setTimeout(() => {
          dataLinkParam.value = [
            {
              code: undefined,
              value: undefined,
              action: "add",
            },
          ];
          showDialogUpdateServiceHealth.value = true;
          ruleFormUpdateServiceHealth.value?.clearValidate();
          scrollToFormUpdateServiceHealth();
        }, 1100);
        routerPush(resNewServiceHealth.result);
      } else {
        toastr.warning(t("toastr_create_failed"));
        return false;
      }
    } else {
      loadingAcceptFormAdd.value = false;
      return false;
    }
  });
};
const setParams = (link, params) => {
  const url = new URL(
    "https://test/" + (link[0] === "/" ? link.slice(1) : link)
  );
  if (!url.search && !url.hash && params.length > 0 && link !== "#") {
    return `${url.pathname}?${params.join("&")}`;
  } else if (url.search && url.hash && params.length > 0) {
    return url.pathname + url.search + "&" + params.join("&") + url.hash;
  } else if (url.hash && params.length > 0) {
    return url.pathname + "?" + params.join("&") + url.hash;
  } else if (url.search && params.length > 0) {
    return url.pathname + url.search + "&" + params.join("&");
  } else if (link === "#" && params.length > 0) {
    return url.pathname + "?" + params.join("&");
  } else {
    return link;
  }
};
const submitFormUpdateSeviceHealth = (formEl) => {
  let checkParam = false;
  const re = /^(\w+)$/;
  dataLinkParam.value.forEach((item) => {
    if (
      item.action === "" &&
      (!item.code || !item.value || !re.test(item.code))
    )
      checkParam = true;
  });

  const listCodeDuplicate = Array.from(
    new Set(
      dataLinkParam.value
        .filter((item) => item.action === "")
        .map((item) => item.code)
    )
  );

  if (
    !formEl ||
    checkParam ||
    dataLinkParam.value.some((item) => listDefaultParam.includes(item.code)) ||
    listCodeDuplicate.length !== dataLinkParam.value.length - 1
  )
    return;
  formEl.validate(async (valid) => {
    loadingAcceptFormUpdate.value = true;
    if (valid) {
      const textLink = await renderLink(
        dataFormServiceHealth.value.link_info.target_type,
        dataFormServiceHealth.value.link_info.target_id
      );
      const listParam = dataLinkParam.value
        .filter((item) => item.action === "")
        .map((item) => {
          return {
            code: item.code ? item.code : null,
            value: item.value ? encodeURIComponent(item.value) : null,
          };
        });

      const strParam = listParam.map((item) => `${item.code}=${item.value}`);

      let service_health_object = {
        id: route.query.id,
        ...dataFormServiceHealth.value,
      };
      service_health_object.normalized_name = service_health_object.name;
      service_health_object.table.sql_table_formats =
        service_health_object.table.sql_table_formats?.length > 0
          ? dataFormServiceHealth.value.table.sql_table_formats.split("\n")
          : [];
      service_health_object.line_chart.sql_table_formats =
        service_health_object.line_chart.sql_table_formats?.length > 0
          ? dataFormServiceHealth.value.line_chart.sql_table_formats.split("\n")
          : [];
      service_health_object.bar_chart.sql_table_formats =
        service_health_object.bar_chart.sql_table_formats?.length > 0
          ? dataFormServiceHealth.value.bar_chart.sql_table_formats.split("\n")
          : [];
      service_health_object.table.service_id = dataFormServiceHealth.value.table
        .service_id
        ? dataFormServiceHealth.value.table.service_id
        : null;
      service_health_object.line_chart.service_id = dataFormServiceHealth.value
        .line_chart.service_id
        ? dataFormServiceHealth.value.line_chart.service_id
        : null;
      service_health_object.bar_chart.service_id = dataFormServiceHealth.value
        .bar_chart.service_id
        ? dataFormServiceHealth.value.bar_chart.service_id
        : null;
      if (!service_health_object.option_link) {
        service_health_object.link_info = null;
      } else {
        service_health_object.link_info.link_parameters = listParam;
        service_health_object.link_info.link =
          dataFormServiceHealth.value.link_info.target_type === "static" &&
          dataFormServiceHealth.value.link_info.link
            ? setParams(dataFormServiceHealth.value.link_info.link, strParam)
            : dataFormServiceHealth.value.link_info.target_type === "static" &&
              !dataFormServiceHealth.value.link_info.link
            ? setParams("/", strParam)
            : setParams(textLink, strParam);
      }

      try {
        const response = await mushroom.service_health.partialUpdateAsync(
          service_health_object
        );
        if (response.result === dataFormServiceHealth.value.id) {
          await getAllServiceHealth();
          setTimeout(() => {
            toastr.success(t("toastr_update_success"));
          }, 1100);
        } else toastr.warning(toastr.success(t("toastr_not_change_warning")));
      } catch (e) {
        MethodService.showError(e.code);
      } finally {
        setTimeout(() => {
          routerPush();
          showDialogUpdateServiceHealth.value = false;
          loadingAcceptFormUpdate.value = false;
        }, 1000);
      }
    } else {
      loadingAcceptFormUpdate.value = false;
      return false;
    }
  });
};
const uploadIcon = async () => {
  try {
    let nameImage = document.getElementById("uploadIcon").files[0]?.name;
    let typeImage = nameImage?.slice(nameImage.indexOf(".")).toLowerCase();

    if (typeImage) {
      if (
        typeImage !== ".jpg" &&
        typeImage !== ".png" &&
        typeImage !== ".jpeg" &&
        typeImage !== ".svg" &&
        typeImage !== ".ico"
      ) {
        toastr.warning(t("t_select_format_image"));
      } else {
        try {
          let res = await mushroom.$file.uploadAsync({
            file: document.getElementById("uploadIcon").files[0],
          });

          if (res.result) {
            dataFormServiceHealth.value.icon_id = res.result;
            dataFormServiceHealth.value.srcIcon = MethodService.thumbIcon(
              res.result,
              32
            );
          }
        } catch (e) {
          console.error(e);
          toastr.warning(t("t_upload_failed"));
        }
      }
    }
  } catch (e) {
    MethodService.showError(e.code);
  }
};
const onInputClick = (event) => {
  event.target.value = "";
};
const scrollToFormUpdateServiceHealth = () => {
  setTimeout(() => {
    refScroll.value.scrollIntoView({ behavior: "smooth" });
  }, 100);
};
const showDialogAdd = () => {
  showDialogAddServiceHealth.value = true;
  dataFormServiceHealth.value.name = undefined;
};
const formatLink = (value) => {
  const link = new URL(
    "https://test/" + (value[0] === "/" ? value.slice(1) : value)
  );
  if (link.search.length > 0) {
    const str = new URLSearchParams(link.search);
    dataLinkParam.value.forEach((item) => {
      if (item.code) {
        str.delete(item.code);
      }
    });
    return (
      link.pathname + (str.toString() ? "?" + str.toString() : "") + link.hash
    );
  }
  return value;
};
const showUpdateForm = async (data) => {
  dataLinkParam.value = [
    {
      code: undefined,
      value: undefined,
      action: "add",
    },
  ];
  if (!data) return;
  ruleFormUpdateServiceHealth.value?.clearValidate();
  routerPush(data.id);
  const resServiceHealthItem = await mushroom.service_health.findByIdAsync({
    id: data.id,
  });
  if (resServiceHealthItem.result) {
    titleServiceHealthForUpdate.value = resServiceHealthItem.result.name;
    if (!resServiceHealthItem.result.default_chart_type)
      resServiceHealthItem.result.default_chart_type = "line";
    !resServiceHealthItem.result.link_info
      ? (resServiceHealthItem.result.option_link = false)
      : (resServiceHealthItem.result.option_link = true);
    if (resServiceHealthItem.result.line_chart) {
      if (resServiceHealthItem.result.line_chart.sql_table_formats?.length > 0)
        resServiceHealthItem.result.line_chart.sql_table_formats =
          resServiceHealthItem.result.line_chart.sql_table_formats.join("\n");
    } else {
      resServiceHealthItem.result.line_chart = {};
      resServiceHealthItem.result.line_chart.sql = undefined;
      resServiceHealthItem.result.line_chart.sql_table_formats = [];
      resServiceHealthItem.result.line_chart.table_config_id = undefined;
      resServiceHealthItem.result.line_chart.service_id = undefined;
    }
    if (resServiceHealthItem.result.bar_chart) {
      if (resServiceHealthItem.result.bar_chart.sql_table_formats?.length > 0)
        resServiceHealthItem.result.bar_chart.sql_table_formats =
          resServiceHealthItem.result.bar_chart.sql_table_formats.join("\n");
    } else {
      resServiceHealthItem.result.bar_chart = {};
      resServiceHealthItem.result.bar_chart.sql = undefined;
      resServiceHealthItem.result.bar_chart.sql_table_formats = [];
      resServiceHealthItem.result.bar_chart.table_config_id = undefined;
      resServiceHealthItem.result.bar_chart.service_id = undefined;
    }
    if (resServiceHealthItem.result.table) {
      if (resServiceHealthItem.result.table.sql_table_formats?.length > 0)
        resServiceHealthItem.result.table.sql_table_formats =
          resServiceHealthItem.result.table.sql_table_formats?.join("\n");
    } else {
      resServiceHealthItem.result.table = {};
      resServiceHealthItem.result.table.sql = undefined;
      resServiceHealthItem.result.table.sql_table_formats = [];
      resServiceHealthItem.result.table.table_config_id = undefined;
      resServiceHealthItem.result.table.service_id = undefined;
    }
    if (resServiceHealthItem.result.icon_id) {
      resServiceHealthItem.result.srcIcon = MethodService.thumbIcon(
        resServiceHealthItem.result.icon_id,
        32
      );
    }

    if (!resServiceHealthItem.result.link_info) {
      resServiceHealthItem.result.option_link = false;
      resServiceHealthItem.result.link_info = {};
    } else resServiceHealthItem.result.option_link = true;

    if (resServiceHealthItem.result.link_info.link_parameters) {
      let listLinkParam = [];
      resServiceHealthItem.result.link_info.link_parameters.forEach((item) => {
        listLinkParam.push({
          code: item.code,
          value: decodeURIComponent(item.value),
          action: "",
        });
      });
      listLinkParam.push({
        code: undefined,
        value: undefined,
        action: "add",
      });
      dataLinkParam.value = listLinkParam;
      // xóa validate param khi mở popup
      errorCode.value = errorCode.value.map((item) => undefined);
      errorValue.value = errorValue.value.map((item) => undefined);
      errorCodeRegex.value = errorCodeRegex.value.map((item) => undefined);
      errorCodeDuplicate.value = errorCodeDuplicate.value.map(
        (item) => undefined
      );
      errorCodeDefault.value = errorCodeDefault.value.map((item) => undefined);

      resServiceHealthItem.result.link_info.link = formatLink(
        resServiceHealthItem.result.link_info.link
      );
    }

    dataFormServiceHealth.value = { ...resServiceHealthItem.result };

    if (resServiceHealthItem?.result?.icon_id) {
      showIcon.value = true;
    } else {
      showIcon.value = false;
      dataFormServiceHealth.value.icon_id = "";
      dataFormServiceHealth.value.srcIcon = "";
    }
    showDialogUpdateServiceHealth.value = true;
    scrollToFormUpdateServiceHealth();
  }
};
const addServiceHealthItem = async (length, index) => {
  const count = index - length;
  listItemScreen.value = listItem.value.slice(
    (tableRules.page - 1) * tableRules.limit,
    (tableRules.page - 1) * tableRules.limit +
      listItemScreen.value.length +
      count
  );
  await getDataServiceHealthItem(index);
};
const getDataServiceHealthItem = async (index) => {
  const data = listItemScreen.value.filter(
    (item) => !serviceHealthData.value.some((x) => x.id === item.id)
  );

  for (let key of data) {
    key.dataChart = undefined;
    key.dataKPI = undefined;
    const dataObject = {
      probe_id: localStorage.getItem("pid"),
      service_health_id: key.id,
      start_date: storeDatePicker.showDatePicker[0],
      stop_date: storeDatePicker.showDatePicker[1],
      vlan_id:
        storeSelectVlan.getValueVlan === "all"
          ? undefined
          : Number(storeSelectVlan.getValueVlan),
    };

    let resKPIPromise = mushroom.probe_data.getServiceHealthDataAsync({
      body: dataObject,
      settings: {
        abortController,
      },
    });

    // tạo 1 biến name = hàm trả về 1 chuỗi ngẫu nhiên không trùng nhau;
    // sau khi gọi xong api thì xóa abort có định danh là name đấy ra khỏi mảng
    try {
      let resChart = await mushroom.probe_data.getServiceHealthChartAsync({
        body: {
          chart_type: key.default_chart_type ? key.default_chart_type : "line",
          ...dataObject,
        },
        settings: {
          abortController,
        },
      });

      key.dataChart = resChart.result;
    } catch (error) {
      key.dataChart = {};
    }
    let resKPI = await resKPIPromise;

    if (!resKPI.result.kpi) {
      key.dataKPI = [];
    } else key.dataKPI = getValueKPI(resKPI.result.kpi);
  }
  // setTimeout(() => {
  //   storeAbortAPI.abortController();
  // }, 10000)
  reRenderServiceHealthItem.value += 1;
  serviceHealthData.value.push(...data);
  isLoadingSkeleton.value = false;
  const count = listItemScreen.value.filter((item) => !checkData(item)).length;

  listItemShow.value = listItemScreen.value.filter((item) => !checkData(item));
  if (count < index) {
    for (let i = 0; i < index - count; i++) {
      listItemShow.value.push("");
    }
  }

  // nếu count = limit hoặc đã check hết list service health
  if (
    count === index ||
    listItemScreen.value.length + (tableRules.page - 1) * tableRules.limit ===
      listItem.value.length ||
    (tableRules.page - 1) * tableRules.limit > listItem.value.length
  ) {
    listItemShow.value = listItemShow.value.filter((item) => item.id);
    storeIsHaveDataDashboard.haveData();
    // if(!storeChangeEdit.showEdit && listItemShow.value.length !== tableRules.limit) {
    //   tableRules.total = (tableRules.page - 1) * tableRules.limit + listItemShow.value.length;
    //   hidePagiNext.value = true;
    // } else {
    //   tableRules.total = totalList.value;
    //   hidePagiNext.value = false;
    // }
    routerPush();
  } else {
    addServiceHealthItem(count, index);
  }
};
const removeVietnameseSearch = (text) => {
  if (!text) return;
  text = text.toString().toLowerCase();
  return MethodService.removeVietnameseTones(text);
};
const getAllListServiceHealth = async () => {
  try {
    const response = await mushroom.service_health.getAllAsync(
      {
        fields:
          "id,index,name,icon_id,default_chart_type,link_info,line_chart,bar_chart,chart,table,created_time,creator_id,last_modified_time,last_modifier_id",
        sort: "index,last_modified_time,created_time",
        filters: !infoSearch.id
          ? Filter.regex(
              "normalized_name",
              new RegExp(removeVietnameseSearch(infoSearch.text))
            ).build()
          : Filter.eq("id", infoSearch.id).build(),
      },
      { enabledCache: false }
    );
    tableRules.total = response.meta.total;
    totalList.value = response.meta.total;
    if (response.result?.length > 0) {
      listItem.value = response.result;
      listServiceHealth.value = JSON.parse(JSON.stringify(listItem.value));
    } else storeIsHaveDataDashboard.noData();
  } catch (e) {
    console.error("error: %o", e);
    storeIsHaveDataDashboard.noData();
    MethodService.showError(e.code);
  } finally {
    setTimeout(() => {
      loadingData.value = false;
    }, 100);
  }
};

const getAllServiceHealth = async () => {
  isLoadingSkeleton.value = true;
  try {
    const response = await mushroom.service_health.getAllAsync(
      {
        fields:
          "id,index,name,icon_id,default_chart_type,link_info,line_chart,bar_chart,chart,table,created_time,creator_id,last_modified_time,last_modifier_id",
        sort: "index,last_modified_time,created_time,icon_id",
        filters: !infoSearch.id
          ? Filter.regex(
              "normalized_name",
              new RegExp(removeVietnameseSearch(infoSearch.text))
            ).build()
          : Filter.eq("id", infoSearch.id).build(),
      },
      { enabledCache: false }
    );
    tableRules.total = response.meta.total;
    totalList.value = response.meta.total;
    if (response.result) {
      listItem.value = response.result;
      listServiceHealth.value = JSON.parse(JSON.stringify(listItem.value));
      if ((tableRules.page - 1) * tableRules.limit > response.result.length) {
        listItemScreen.value = [];
      } else if (response.result.length > tableRules.limit)
        listItemScreen.value = response.result.slice(
          (tableRules.page - 1) * tableRules.limit,
          tableRules.page * tableRules.limit
        );
      else listItemScreen.value = response.result;
      if (listItemScreen.value.length > 0) {
        serviceHealthData.value = [];
        await getDataServiceHealthItem(listItemScreen.value.length);
      } else {
        listItemShow.value = [];
      }
    } else {
      storeIsHaveDataDashboard.noData();
    }
  } catch (e) {
    storeIsHaveDataDashboard.noData();
    MethodService.showError(e.code);
  } finally {
    statusSearch.value = "";
    loadingData.value = false;
  }
};
const getDataScreen = async () => {
  isLoadingSkeleton.value = true;
  listItemScreen.value = listItem.value.slice(
    (tableRules.page - 1) * tableRules.limit,
    tableRules.page * tableRules.limit
  );
  const value = valueMode.value
    .substr(valueMode.value.lastIndexOf(":") + 2)
    .toLowerCase();
  let listQuery = {
    start_date: MethodService.formatDateYYYYMMDD(
      storeDatePicker.showDatePicker[0]
    ),
    stop_date: MethodService.formatDateYYYYMMDD(
      storeDatePicker.showDatePicker[1]
    ),
    limit: tableRules.limit,
    page: tableRules.page,
  };
  if (value && value.length > 0 && value !== "all") listQuery.vlan_id = value;

  router
    .replace({
      name: "ServiceHealth",
      query: listQuery,
    })
    .catch(() => {});

  if (listItemScreen.value.length > 0) {
    await getDataServiceHealthItem(listItemScreen.value.length);
  } else {
    listItemShow.value = [];
  }
};
const getValueKPI = (data) => {
  if (Object.entries(data).length === 0) {
    return {};
  } else {
    let arr = [];
    for (const key in data) {
      listServiceHealthKPI.value.forEach((item) => {
        if (key === item.code) {
          arr.push({
            label: item.label,
            value: MethodService.fixedNumber(data[key]),
            color: MethodService.getColorByRange(
              data[key],
              item.value_settings
            ),
            unit: item.unit,
            link: item.link,
            tooltip: item.tooltip,
            icon: item.icon_id
              ? MethodService.thumbIcon(item.icon_id, 32)
              : null,
            code: item.code,
          });
        }
      });
    }
    return arr;
  }
};
const routerPush = (idServiceHealth) => {
  let queryForPush = {
    start_date: MethodService.formatDateYYYYMMDD(
      storeDatePicker.showDatePicker[0]
    ),
    stop_date: MethodService.formatDateYYYYMMDD(
      storeDatePicker.showDatePicker[1]
    ),
    limit: tableRules.limit,
    page: tableRules.page,
  };

  if (idServiceHealth) queryForPush.id = idServiceHealth;
  if (storeSelectVlan.getValueVlan !== "all")
    queryForPush.vlan_id = storeSelectVlan.getValueVlan;

  router.push({
    path: "/service-health",
    query: queryForPush,
  });
};
const getServiceHealthKpi = async () => {
  try {
    const response = await mushroom.service_health_kpi.getAllAsync({
      filters: Filter.eq("visible", true).build(),
      sort: "index",
    });
    if (response.result) {
      listServiceHealthKPI.value = response.result;
      const arr = [];
      response.result.forEach((item) => {
        arr.push({
          id: item.id,
          code: item.code,
          target_domain: item.target_domain,
        });
      });
      listKPI.value = arr;
    }
  } catch (e) {
    console.error("error: %o", e);
  }
};
const closeAddForm = () => {
  showDialogAddServiceHealth.value = false;
  ruleFormAddServiceHealth.value?.clearValidate();
  routerPush();
};
const closeUpdateForm = () => {
  showDialogUpdateServiceHealth.value = false;
  ruleFormUpdateServiceHealth.value?.clearValidate();
  routerPush();
};
const trimString = () => {
  if (!dataFormServiceHealth.value.name) return;
  dataFormServiceHealth.value.name = dataFormServiceHealth.value.name
    ?.trim()
    .replaceAll(/\s+/g, " ");
};
const getVlan = async () => {
  try {
    const response = await mushroom.probe_data.getVlanIdsAsync({
      body: {
        probe_id: localStorage.getItem("pid"),
      },
      settings: {
        abortController,
      },
    });
    if (response.result?.length > 0) {
      listVlanIDs.value = [];
      response.result.forEach((item) => {
        listVlanIDs.value.push({
          value: item,
          label: "VLAN : " + item,
        });
      });
    }
    listVlanIDs.value.unshift({
      value: "all",
      label: "VLAN : ALL",
    });

    // check vlan url
    const listVlanName = listVlanIDs.value?.map((item) => item.value);
    const vlan_id = route.query.vlan_id ?? storeSelectVlan.getValueVlan;
    if (vlan_id && !listVlanName?.includes(vlan_id)) {
      disableCallChangePage.value = true;
      storeSelectVlan.changeSelectVlan("all");
    }
  } catch (error) {
    console.error("error: %o", error);
  }
};
const setValueVlanToUrl = async (valueVlan, index) => {
  const domDropVlan = document.querySelectorAll(".drop-vlan");
  domDropVlan?.forEach((item) => item.classList.remove("activeLabel"));
  const domSelect = document.getElementById("active-" + index);
  domSelect?.classList.add("activeLabel");
  valueMode.value = valueVlan;
  storeSelectVlan.changeSelectVlan(valueVlan);
  let value = valueVlan.substr(valueVlan.lastIndexOf(":") + 2).toLowerCase();

  router.push({
    path: "/service-health",
    query: {
      start_date: MethodService.formatDateYYYYMMDD(
        storeDatePicker.showDatePicker[0]
      ),
      stop_date: MethodService.formatDateYYYYMMDD(
        storeDatePicker.showDatePicker[1]
      ),
      vlan_id: value,
      limit: tableRules.limit,
      page: tableRules.page,
    },
  });
  storeSelectVlan.changeSelectVlan(value);
  await getAllServiceHealth();
};
const deleteServiceHealth = () => {
  showDialogUpdateServiceHealth.value = false;
  Swal.fire({
    title: t("swal_noti"),
    html: `${titleServiceHealthForUpdate.value} ${t("t_will_delete")}`,
    icon: "warning",
    confirmButtonColor: "#f46a6a",
    confirmButtonText: t("swal_accept"),
    cancelButtonText: t("swal_cancel"),
    showCancelButton: true,
    allowOutsideClick: false,
  }).then(async (result) => {
    if (result.isConfirmed) {
      loadingData.value = true;
      try {
        const response = await mushroom.service_health.deleteAsync({
          id: route.query.id,
        });
        if (response.result == 1) toastr.success(t("toastr_delete_success"));
        isLoadingSkeleton.value = true;
        await getAllServiceHealth();
      } catch (e) {
        console.error("error: %o", e);
        MethodService.showError(e.code);
      }
    } else showDialogUpdateServiceHealth.value = true;
  });
};
const changeTargetType = async (e) => {
  dataFormServiceHealth.value.link_info.target_id = undefined;
  dataFormServiceHealth.value.link_info.link = undefined;
  if (e === "service") await getListDichVu();
  if (e === "table_screen") await getListTableScreen();
};
const changeTargetDomain = async () => {
  dataFormServiceHealth.value.link_info.target_id = undefined;
  await getListDichVu();
  await getListTableScreen();
};
const downloadCard = async () => {
  storeIsHaveDataDashboard.downloadData();
  const search = location.search.substring(1);
  const data = search
    ? JSON.parse(
        '{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
        function (key, value) {
          return key === "" ? value : decodeURIComponent(value);
        }
      )
    : "";

  // 1. gửi lên để có id request
  const request_download_dashboard_object = {
    probe_id: localStorage.pid,
    type: "service_health", // xem lại type -> service-health có phải là 1 dashboard?
    start_date: storeDatePicker.showDatePicker[0],
    stop_date: storeDatePicker.showDatePicker[1],
    data: search ? JSON.stringify(data) : undefined,
  };
  try {
    const newId = await mushroom.request_download_dashboard.createAsync(
      request_download_dashboard_object
    );
    if (newId.result) {
      idRequestDownload.value = newId.result;
    }
    // 2. nếu request thành công, check trạng thái 5s 1 lần
    if (idRequestDownload.value.length > 0) {
      window.checkStatusDownloadProbe = setTimeout(checkStatus, 5000);
    } else clearTimeout(window.checkStatusDownloadProbe);
  } catch (error) {
    console.error("Có lỗi: %o", error);
  }
};
const checkStatus = async () => {
  console.log("cứ 5s gọi log 1 lần, id: ", idRequestDownload.value);
  let response;
  try {
    response = await mushroom.request_download_dashboard.findByIdAsync(
      {
        id: idRequestDownload.value,
      },
      { enabledCache: false }
    );
    console.log(
      "Thông tin request_download_dashboard trả về: %o",
      response.result.status
    );
  } catch (e) {
    console.error("Có lỗi: %o", e);
    window.checkStatusDownloadProbe = setTimeout(checkStatus, 5000);
    return;
  }

  if (response.result.status == "error") {
    window.toastr.error(response.result.note);
    clearTimeout(window.checkStatusDownloadProbe);
    storeIsHaveDataDashboard.stopDownloadData();
    return;
  }
  if (response.result.status == "completed") {
    await MethodService.downloadFileDashboard(
      idRequestDownload.value,
      "dashboard_data.xlsx"
    );
    clearTimeout(window.checkStatusDownloadProbe);
    storeIsHaveDataDashboard.stopDownloadData();
    return;
  }
  window.checkStatusDownloadProbe = setTimeout(checkStatus, 5000);
};
const updateSortServiceHealth = async (data) => {
  let listPromise = [];
  data.forEach((service, i) => {
    const serviceData = {
      id: service.id,
      index: i,
    };
    listPromise.push(mushroom.service_health.partialUpdateAsync(serviceData));
  });
  try {
    await Promise.all(listPromise).then(async () => {
      toastr.success(t("toastr_update_success"));
      serviceHealthData.value = data;
      showDialogSort.value = false;
      await getAllServiceHealth();
    });
  } catch (error) {
    MethodService.showError(error.code);
  }
};
const handleClose = (done) => {
  done();
  listServiceHealth.value = JSON.parse(JSON.stringify(listItem.value));
};
const hashUrl = () => {
  if (route.query?.limit) {
    if (
      route.query.limit === "12" ||
      route.query.limit === "24" ||
      route.query.limit === "36" ||
      route.query.limit === "48"
    )
      tableRules.limit = Number(route.query.limit);
    else tableRules.limit = 24;
  }
  if (route.query?.page) tableRules.page = Number(route.query.page);
  routerPush();
  if (route.query.vlan_id) {
    disableCallChangePage.value = true;
    storeSelectVlan.changeSelectVlan(route.query.vlan_id);
  }
};
const fn_tableSizeChange = (limit) => {
  tableRules.limit = limit;
  tableRules.page = 1;
  fn_tableChangeOffset(tableRules.page);
};
const fn_tableCurentChange = (page) => {
  listItemScreen.value = [];
  listItemShow.value = ["", "", "", "", "", "", "", "", "", "", "", ""];
  const element = document.querySelector(".service-health__scroll");
  element.scrollTop = 0;
  // hidePagiNext.value = true;
  fn_tableChangeOffset(page);
};
const fn_tableChangeOffset = (page) => {
  tableRules.page = page;
  tableRules.offset = (tableRules.page - 1) * tableRules.limit;
  getDataScreen();
};
const searchServiceHealth = async (search) => {
  infoSearch.text = search.name;
  infoSearch.id = search.id;
  statusSearch.value = "";
  inputSearch.value.focus();
  await getAllServiceHealth();
};
const onSubmitSearch = async () => {
  statusSearch.value = "";
  await getAllServiceHealth();
};
const checkChangeKeySearch = () => {
  infoSearch.id = undefined;
  if (window.waitSearch) clearTimeout(window.waitSearch);
  window.waitSearch = setTimeout(async () => {
    statusSearch.value = "loading";
    await getAllNamesServiceHealth(infoSearch.text);
  }, 700);
};
const getAllNamesServiceHealth = async (text) => {
  if (!text || text.trim() === "") {
    statusSearch.value = "";
    isDisabledButtonSearch.value = false;
    return;
  }
  arrayServiceHeathName.value = [];
  try {
    const response = await mushroom.service_health.listAsync(
      {
        fields: "id,index,name",
        sort: "index",
        filters: Filter.regex(
          "normalized_name",
          new RegExp(removeVietnameseSearch(text))
        ).build(),
      },
      { enabledCache: false }
    );
    if (!response.result || response.result?.length === 0) {
      setTimeout(() => {
        statusSearch.value = "no-data";
        isDisabledButtonSearch.value = true;
      }, 800);
    } else {
      response.result.forEach((item) => {
        if (!checkData(item))
          arrayServiceHeathName.value.push({
            id: item.id,
            name: item.name,
          });
      });
      setTimeout(() => {
        statusSearch.value = "have-data";
        isDisabledButtonSearch.value = false;
      }, 800);
    }
  } catch (e) {
    statusSearch.value = "";
    MethodService.showError(e.code);
  } finally {
    isDisabledButtonSearch.value = false;
  }
};
watch(
  () => storeSelectVlan.getValueVlan,
  (valueVlan) => {
    valueVlan.indexOf("VLAN") !== -1
      ? (valueMode.value = valueVlan)
      : (valueMode.value =
          "VLAN : " + (valueVlan === "all" ? "ALL" : valueVlan));

    if (!disableCallChangePage.value) {
      fn_tableCurentChange(1);
    }
    disableCallChangePage.value = false;
  }
);
watch(
  () => storeDatePicker.showDatePicker,
  async () => {
    hashUrl();
    if (!disableCallApiGetAllServiceHealth.value) {
      tableRules.page = 1;
      await getAllServiceHealth();
    }
  }
);

onMounted(async () => {
  storeAbortAPI.setAbort(abortController);
  nameProbe.value = localStorage.getItem("probeName");
  if (route.query?.start_date) {
    disableCallApiGetAllServiceHealth.value = true;
    localStorage.setItem("dateSelect", [
      route.query.start_date,
      route.query.stop_date,
    ]);
    storeDatePicker.setDatePicker([
      route.query.start_date,
      route.query.stop_date,
    ]);
  }
  hashUrl();
  await getVlan();
  await storeListMenu.getListMenuPhanCap();
  try {
    await getServiceHealthKpi();
    await getAllServiceHealth();
  } finally {
    disableCallApiGetAllServiceHealth.value = false;
  }
  await storeListService.getAllListService();
  getAllService();
  await getListDichVu();
  await getListUsecase();
  await getListTableScreen();
  await getListDynamic();
  await getAllTableConfig();
});
</script>

<template>
  <div class="health-container">
    <Layout>
      <loading v-if="loadingData"></loading>

      <div class="service-health">
        <div class="service-health__header">
          <div class="d-flex justify-content-between align-items-center">
            <div class="service-health__header--name">
              {{ nameProbe }}
            </div>
            <div class="service-health__header--group">
              <div
                v-if="storeChangeEdit.showEdit"
                class="service-health__button-add"
                :title="$t('t-add')"
                @click="showDialogAdd"
              >
                <i class="ri-add-fill"></i>
              </div>
              <button
                class="btn btn-info btn-border"
                @click="showDialogSort = !showDialogSort"
                v-if="storeChangeEdit.showEdit"
              >
                <i class="ri-drag-move-2-fill"></i>
                {{ $t("t-sort") }}
              </button>
            </div>
          </div>
          <div class="d-flex justify-content-between align-items-center mt-1">
            <div
              class="service-health__header--dropdown"
              ref="dropdown"
              :class="{ focusDropdown: countDropdown % 2 === 0 }"
              @click="countDropdown++"
              v-click-outside="
                () => {
                  countDropdown = 1;
                }
              "
            >
              <div class="service-health__header--dropdown--header">
                <div class="service-health__header--dropdown--header--group">
                  <img :src="VLAN" alt="vlan" height="32" />
                  <p>{{ valueMode }}</p>
                </div>
                <img
                  :src="ArrowLineLef2"
                  alt="arrow-line"
                  :class="{ rotate: countDropdown % 2 === 0 }"
                />
              </div>
              <div
                class="service-health__header--dropdown--list"
                :class="{ heightDroplist: countDropdown % 2 === 0 }"
              >
                <div
                  v-for="(item, index) in listVlanIDs"
                  :key="item.id"
                  @click="setValueVlanToUrl(item.label, index)"
                  :class="{ hide: countDropdown % 2 === 1 }"
                >
                  <img :src="VLAN" alt="vlan" height="32" />
                  <span
                    class="drop-vlan"
                    :id="`active-${index}`"
                    :class="{ activeLabel: valueMode === item.label }"
                    >{{ item.label }}</span
                  >
                </div>
              </div>
            </div>
            <div class="d-flex">
              <form
                :model="infoSearch"
                @submit.prevent
                class="service-health__search"
              >
                <div class="service-health__search--input">
                  <el-input
                    v-model="infoSearch.text"
                    :placeholder="`${$t('t_service_health_search')}`"
                    clearable
                    @input="checkChangeKeySearch()"
                    ref="inputSearch"
                  />
                </div>
                <el-button
                  type="primary"
                  native-type="submit"
                  @click="onSubmitSearch"
                  :disabled="isDisabledButtonSearch || isLoadingSkeleton"
                  style="display: none"
                  >{{ $t("t-search") }}</el-button
                >
                <div
                  class="service-health__search--select"
                  v-if="statusSearch === 'have-data'"
                >
                  <div
                    class="search__item"
                    v-for="item in arrayServiceHeathName"
                    :key="item.id"
                    @click="searchServiceHealth(item)"
                  >
                    {{ item.name }}
                  </div>
                </div>
                <div
                  class="service-health__search--status"
                  v-if="statusSearch === 'loading'"
                >
                  <div class="text-center">
                    <i
                      style="font-size: 20px; opacity: 0.5; color: #0f6a33"
                      class="bx bx-loader-alt loading-icon"
                    ></i>
                    <p>{{ $t("t-loading-get-data") }}</p>
                  </div>
                </div>
                <div
                  class="service-health__search--status"
                  v-if="statusSearch === 'no-data'"
                >
                  <div class="text-center">
                    <i
                      style="font-size: 20px; opacity: 0.5; color: #e0ba00"
                      class="ri-information-line"
                    ></i>
                    <p>
                      {{ $t("t-no-data") }}
                    </p>
                  </div>
                </div>
              </form>
              <div
                class="service-health__button-search"
                :title="$t('t-search')"
                @click="getAllServiceHealth"
              >
                <i
                  class="ri-search-2-line fs-22 service-health-search-icon"
                ></i>
              </div>
            </div>
          </div>
        </div>
        <div class="service-health__scroll">
          <div class="service-health__body">
            <el-row
              :gutter="24"
              class="service-health__list"
              v-if="!storeChangeEdit.showEdit"
            >
              <template v-if="listItemShow.length > 0">
                <el-col
                  :md="6"
                  class="service-health__item"
                  v-for="item in listItemShow"
                  :key="item.id"
                  :class="{
                    displayNone: checkData(item),
                  }"
                >
                  <ServiceHealthItem
                    :data="serviceHealthData.find((x) => x.id === item.id)"
                    :dataItem="{ ...item }"
                    :dataKPI="listKPI"
                    @show-dialog-update="showUpdateForm"
                    :loadingSkeleton="isLoadingSkeleton"
                    :key="reRenderServiceHealthItem"
                  ></ServiceHealthItem>
                </el-col>
              </template>
              <div v-else style="width: 100%; margin-top: 100px">
                <div class="general-alert">
                  <i class="mdi mdi-database-alert-outline mx-2"></i>
                  {{ $t("t-not-data") }}
                </div>
              </div>
            </el-row>
            <el-row :gutter="24" class="service-health__list" v-else>
              <template
                v-if="listItemScreen.slice(0, tableRules.limit).length > 0"
              >
                <el-col
                  :md="6"
                  class="service-health__item"
                  v-for="item in listItemScreen.slice(0, tableRules.limit)"
                  :key="item.id"
                >
                  <ServiceHealthItem
                    :data="serviceHealthData.find((x) => x.id === item.id)"
                    :dataItem="{ ...item }"
                    :dataKPI="listKPI"
                    @show-dialog-update="showUpdateForm"
                    :loadingSkeleton="isLoadingSkeleton"
                    :key="reRenderServiceHealthItem"
                  ></ServiceHealthItem>
                </el-col>
              </template>
              <div v-else style="width: 100%; margin-top: 100px">
                <div class="general-alert">
                  <i class="mdi mdi-database-alert-outline mx-2"></i>
                  {{ $t("t-not-data") }}
                </div>
              </div>
            </el-row>

            <div
              v-if="
                listItemShow.length > 0 ||
                (storeChangeEdit.showEdit &&
                  listItemScreen.slice(0, tableRules.limit).length > 0)
              "
              class="pagination"
              :class="{
                hide: hidePagiNext,
              }"
              style="float: right"
            >
              <el-config-provider :locale="lang_web.showLang == 'vi' ? vi : en">
                <el-pagination
                  small
                  v-model:currentPage="tableRules.page"
                  v-model:page-size="tableRules.limit"
                  :page-sizes="tableRules.lengthMenu"
                  background
                  layout="sizes, prev, pager, next, jumper"
                  :total="tableRules.total"
                  @size-change="fn_tableSizeChange"
                  @current-change="fn_tableCurentChange"
                />
              </el-config-provider>
            </div>
          </div>
        </div>

        <!-- dialog hiển thị thêm service-health mới -->
        <el-dialog
          v-model="showDialogAddServiceHealth"
          :title="$t('t-add') + ' service-health'"
          :before-close="closeAddForm"
          class="dialog-service-health dialog-service-health-add"
          :close-on-click-modal="false"
          align-center
          @submit.prevent
        >
          <el-form
            ref="ruleFormAddServiceHealth"
            label-position="top"
            :model="dataFormServiceHealth"
            status-icon
            :rules="rules"
            label-width="120px"
            class="dialog-service-health__form"
          >
            <el-form-item :label="$t('t-title-card-item')" prop="name">
              <el-input
                autofocus
                v-model="dataFormServiceHealth.name"
                :placeholder="$t('t_input_name')"
                @change="trimString"
              ></el-input>
            </el-form-item>
            <div class="action-form">
              <el-button
                native-type="submit"
                type="primary"
                :loading="loadingAcceptFormAdd"
                @click="submitFormAddSeviceHealth(ruleFormAddServiceHealth)"
                >{{ $t("t-add") }}</el-button
              >
            </div>
          </el-form>
        </el-dialog>
        <!-- dialog cập nhật service-health -->
        <el-dialog
          v-model="showDialogUpdateServiceHealth"
          :title="
            $t('action_update') +
            ' service-health ' +
            titleServiceHealthForUpdate
          "
          :before-close="closeUpdateForm"
          class="dialog-service-health"
          :close-on-click-modal="false"
          align-center
        >
          <div class="scroll">
            <div ref="refScroll"></div>
            <el-form
              ref="ruleFormUpdateServiceHealth"
              label-position="top"
              :model="dataFormServiceHealth"
              status-icon
              :rules="rules"
              label-width="120px"
              class="dialog-service-health__form"
              scroll-to-error
            >
              <el-form-item :label="$t('t-title-card-item')" prop="name">
                <el-input
                  ref="name"
                  autofocus
                  v-model="dataFormServiceHealth.name"
                  @change="trimString"
                ></el-input>
              </el-form-item>
              <el-form-item
                :label="$t('t-chart-type')"
                prop="default_chart_type"
              >
                <el-select
                  v-model="dataFormServiceHealth.default_chart_type"
                  :placeholder="$t('t-select-chart-type')"
                  ref="default_chart_type"
                >
                  <el-option
                    v-for="item in listTypeChart"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>

              <div class="d-flex justify-content-between">
                <div class="icon-group">
                  <p>Icon:</p>
                  <div v-show="showIcon" class="img-icon-service-health">
                    <img
                      :src="dataFormServiceHealth.srcIcon"
                      alt="icon service-health"
                    />
                  </div>
                  <div v-show="!showIcon" class="img-icon-service-health">
                    <img
                      src="@/assets/icon/icon-service-health/no-logo.png"
                      alt="none icon"
                      height="32"
                    />
                  </div>

                  <label for="uploadIcon" class="icon-group__upload"
                    ><img
                      src="../../assets/icon/upload_white.svg"
                      alt="upload"
                    />
                    <span>{{ $t("t_upload") }}</span></label
                  >
                  <input
                    type="file"
                    accept=".jpg, .png, .jpeg, .svg, .ico"
                    id="uploadIcon"
                    style="display: none"
                    @change="uploadIcon"
                    @click="onInputClick"
                  />
                </div>
              </div>

              <div class="dialog-service-health__block">
                <div class="dialog-service-health__block--title">
                  <i class="ri-table-fill"></i>{{ $t("t_config_kpi") }}
                </div>
                <el-form-item :label="$t('t-select-service')">
                  <el-select
                    filterable
                    v-model="dataFormServiceHealth.table.service_id"
                    :placeholder="$t('t-select-service')"
                    clearable
                    @change="getListTableConfigForKPI"
                  >
                    <el-option
                      v-for="item in listServices"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item
                  :label="$t('t-select-table-config')"
                  prop="table.table_config_id"
                >
                  <el-select
                    filterable
                    v-model="dataFormServiceHealth.table.table_config_id"
                    :placeholder="$t('t-select-table-config')"
                    ref="table.table_config_id"
                  >
                    <el-option
                      v-for="item in listTableConfigForKPI"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item label="SQL" prop="table.sql">
                  <el-input
                    ref="table.sql"
                    type="textarea"
                    :rows="5"
                    :input-style="{
                      wordBreak: 'break-word',
                    }"
                    v-model="dataFormServiceHealth.table.sql"
                    :placeholder="$t('t_sql_must_have_table')"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  :label="$t('t_format_born_table')"
                  prop="table.sql_table_formats"
                >
                  <el-input
                    type="textarea"
                    :rows="5"
                    :input-style="{
                      wordBreak: 'break-word',
                    }"
                    v-model="dataFormServiceHealth.table.sql_table_formats"
                    :placeholder="$t('t_line_must_have_table_name')"
                  ></el-input>
                </el-form-item>
              </div>

              <div class="dialog-service-health__block">
                <div class="dialog-service-health__block--title">
                  <i class="ri-line-chart-fill"></i
                  >{{ $t("t_config_line_chart") }}
                </div>
                <el-form-item :label="$t('t-select-service')">
                  <el-select
                    filterable
                    v-model="dataFormServiceHealth.line_chart.service_id"
                    :placeholder="$t('t-select-service')"
                    clearable
                    @change="getListTableConfigForLineChart"
                  >
                    <el-option
                      v-for="item in listServices"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item
                  :label="$t('t-select-table-config')"
                  prop="line_chart.table_config_id"
                >
                  <el-select
                    filterable
                    v-model="dataFormServiceHealth.line_chart.table_config_id"
                    :placeholder="$t('t-select-table-config')"
                    ref="line_chart.table_config_id"
                  >
                    <el-option
                      v-for="item in listTableConfigForLineChart"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item label="SQL" prop="line_chart.sql">
                  <el-input
                    ref="line_chart.sql"
                    type="textarea"
                    :rows="5"
                    :input-style="{
                      wordBreak: 'break-word',
                    }"
                    v-model="dataFormServiceHealth.line_chart.sql"
                    :placeholder="$t('t_sql_must_have_table')"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  :label="$t('t_format_born_table')"
                  prop="line_chart.sql_table_formats"
                >
                  <el-input
                    type="textarea"
                    :rows="5"
                    :input-style="{
                      wordBreak: 'break-word',
                    }"
                    v-model="dataFormServiceHealth.line_chart.sql_table_formats"
                    :placeholder="$t('t_line_must_have_table_name')"
                  ></el-input>
                </el-form-item>
              </div>

              <div class="dialog-service-health__block">
                <div class="dialog-service-health__block--title">
                  <i class="ri-bar-chart-fill"></i
                  >{{ $t("t_config_bar_chart") }}
                </div>
                <el-form-item :label="$t('t-select-service')">
                  <el-select
                    filterable
                    v-model="dataFormServiceHealth.bar_chart.service_id"
                    :placeholder="$t('t-select-service')"
                    clearable
                    @change="getListTableConfigForBarChart"
                  >
                    <el-option
                      v-for="item in listServices"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item
                  :label="$t('t-select-table-config')"
                  prop="bar_chart.table_config_id"
                >
                  <el-select
                    filterable
                    v-model="dataFormServiceHealth.bar_chart.table_config_id"
                    :placeholder="$t('t-select-table-config')"
                    ref="bar_chart.table_config_id"
                  >
                    <el-option
                      v-for="item in listTableConfigForBarChart"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item label="SQL" prop="bar_chart.sql">
                  <el-input
                    ref="bar_chart.sql"
                    type="textarea"
                    :rows="5"
                    :input-style="{
                      wordBreak: 'break-word',
                    }"
                    v-model="dataFormServiceHealth.bar_chart.sql"
                    :placeholder="$t('t_sql_must_have_table')"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  :label="$t('t_format_born_table')"
                  prop="bar_chart.sql_table_formats"
                >
                  <el-input
                    type="textarea"
                    :rows="5"
                    :input-style="{
                      wordBreak: 'break-word',
                    }"
                    v-model="dataFormServiceHealth.bar_chart.sql_table_formats"
                    :placeholder="$t('t_line_must_have_table_name')"
                  ></el-input>
                </el-form-item>
              </div>

              <div class="dialog-service-health__block">
                <div class="dialog-service-health__block--title option-link">
                  <div class="option-link__item">
                    <i class="ri-link-m"></i>{{ $t("t_config_link") }}
                  </div>
                  <div>
                    <el-checkbox
                      v-model="dataFormServiceHealth.option_link"
                      :label="$t('t-link-config')"
                    />
                  </div>
                </div>
                <div v-if="dataFormServiceHealth.option_link">
                  <el-form-item
                    :label="$t('t-target-domains')"
                    prop="link_info.target_domain"
                  >
                    <el-select
                      v-model="dataFormServiceHealth.link_info.target_domain"
                      filterable
                      :placeholder="`${$t('t-placeholder-target-domains')}`"
                      style="width: 100%"
                      :no-match-text="$t('t-match-data')"
                      :no-data-text="$t('t-no-data')"
                      @change="changeTargetDomain"
                    >
                      <el-option
                        v-for="item in targetDomainsSelect"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                  </el-form-item>
                  <el-form-item
                    :label="$t('t-target-type')"
                    prop="link_info.target_type"
                  >
                    <el-select
                      v-model="dataFormServiceHealth.link_info.target_type"
                      filterable
                      :placeholder="`${$t('t-placeholder-target-type')}`"
                      style="width: 100%"
                      :no-match-text="$t('t-match-data')"
                      :no-data-text="$t('t-no-data')"
                      @change="changeTargetType"
                    >
                      <el-option
                        v-for="item in targetTypeSelect"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                  </el-form-item>
                  <el-form-item
                    label="Link"
                    v-if="
                      dataFormServiceHealth.link_info?.target_type ===
                        'static' ||
                      dataFormServiceHealth.link_info?.target_type === undefined
                    "
                    prop="link_info.link"
                  >
                    <el-input
                      v-model="dataFormServiceHealth.link_info.link"
                      :placeholder="$t('t-place-link')"
                    ></el-input>
                  </el-form-item>
                  <el-form-item
                    label="Target"
                    prop="link_info.target_id"
                    v-else
                  >
                    <el-select
                      filterable
                      v-if="
                        dataFormServiceHealth.link_info.target_type ===
                        'service'
                      "
                      v-model="dataFormServiceHealth.link_info.target_id"
                      :placeholder="$t('t-select-service')"
                      :no-match-text="$t('t-match-data')"
                      :no-data-text="$t('t-no-data')"
                    >
                      <el-option
                        v-for="item in listDichVu"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                      />
                    </el-select>
                    <el-select
                      filterable
                      v-if="
                        dataFormServiceHealth.link_info.target_type ===
                        'table_screen'
                      "
                      v-model="dataFormServiceHealth.link_info.target_id"
                      :placeholder="$t('t-select-table-screen')"
                      :no-match-text="$t('t-match-data')"
                      :no-data-text="$t('t-no-data')"
                    >
                      <el-option
                        v-for="item in listTableScreen"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                      />
                    </el-select>
                    <el-select
                      filterable
                      v-if="
                        dataFormServiceHealth.link_info.target_type ===
                        'usecase'
                      "
                      v-model="dataFormServiceHealth.link_info.target_id"
                      :placeholder="$t('t-select-usecase')"
                      :no-match-text="$t('t-match-data')"
                      :no-data-text="$t('t-no-data')"
                    >
                      <el-option
                        v-for="item in listUsecase"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                    <el-select
                      filterable
                      v-if="
                        dataFormServiceHealth.link_info.target_type ===
                        'dynamic'
                      "
                      v-model="dataFormServiceHealth.link_info.target_id"
                      placeholder="Chọn dynamic"
                      :no-match-text="$t('t-match-data')"
                      :no-data-text="$t('t-no-data')"
                    >
                      <el-option
                        v-for="item in listDynamic"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                      />
                    </el-select>
                  </el-form-item>

                  <el-form-item
                    :label="$t('t-parameters-list')"
                    class="nguong-mau-sac"
                  >
                    <el-table :data="dataLinkParam.value">
                      <el-table-column
                        prop="code"
                        min-width="100"
                        align="start"
                      >
                        <template #header>
                          <div class="info-param">
                            <span>Variable</span>
                            <el-tooltip
                              class="box-item"
                              effect="dark"
                              placement="top-start"
                              popper-class="tooltip-param"
                            >
                              <template #content>
                                {{
                                  $t("t_variations_conditions", {
                                    list: listDefaultParam.join(`, `),
                                  })
                                }}
                              </template>
                              <i class="ri-question-line me-1 align-bottom"></i>
                            </el-tooltip>
                          </div>
                        </template>
                        <template #default="scope">
                          <el-input
                            type="text"
                            v-model="scope.row.code"
                            placeholder="Variable"
                            :class="{
                              error_number:
                                errorCode[scope.$index] === scope.$index ||
                                errorCodeRegex[scope.$index] === scope.$index ||
                                errorCodeDuplicate[scope.$index] ===
                                  scope.$index ||
                                errorCodeDefault[scope.$index] === scope.$index,
                            }"
                            @change="
                              changeParam(
                                scope.row.code,
                                scope.row.value,
                                scope.$index,
                                'code'
                              )
                            "
                          />
                          <span
                            v-if="errorCode[scope.$index] === scope.$index"
                            class="error"
                            >{{ $t("valid_required") }}</span
                          >
                          <span
                            v-if="errorCodeRegex[scope.$index] === scope.$index"
                            class="error"
                            >{{ $t("valid_checkCode") }}</span
                          >
                          <span
                            v-if="
                              errorCodeDuplicate[scope.$index] === scope.$index
                            "
                            class="error"
                            >{{ $t("valid_existed_variables") }}</span
                          >
                          <span
                            v-if="
                              errorCodeDefault[scope.$index] === scope.$index
                            "
                            class="error"
                            >{{ $t("valid_variables_not_allowed") }}</span
                          >
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="value"
                        label="Value"
                        min-width="100"
                        align="start"
                      >
                        <template #default="scope">
                          <el-input
                            type="text"
                            v-model="scope.row.value"
                            placeholder="Value"
                            :class="{
                              error_number:
                                errorValue[scope.$index] === scope.$index,
                            }"
                            @change="
                              changeParam(
                                scope.row.code,
                                scope.row.value,
                                scope.$index,
                                'value'
                              )
                            "
                          />
                          <span
                            v-if="errorValue[scope.$index] === scope.$index"
                            class="error"
                            >{{ $t("valid_required") }}</span
                          >
                        </template>
                      </el-table-column>
                      <el-table-column
                        :label="$t('t-action')"
                        align="center"
                        width="100px"
                      >
                        <template #default="scope">
                          <div class="btn-group-thao-tac">
                            <button
                              type="button"
                              class="btn btn-soft-info waves-effect waves-light btn-sm"
                              v-b-popover.hover.left="$t('swal_add')"
                              v-if="scope.row.action === 'add'"
                              @click="addParam(scope.row, scope.$index)"
                            >
                              <i class="ri-add-circle-line"></i>
                            </button>
                            <button
                              type="button"
                              class="btn btn-soft-danger waves-effect waves-light btn-sm"
                              v-b-popover.hover.left="$t('t-delete')"
                              v-else
                              @click="deleteParam(scope.$index)"
                            >
                              <i class="ri-delete-bin-6-line"></i>
                            </button>
                          </div>
                        </template>
                      </el-table-column>
                    </el-table>
                  </el-form-item>
                </div>
              </div>

              <div class="action-form">
                <el-button type="danger" plain @click="deleteServiceHealth">{{
                  $t("action_delete")
                }}</el-button>
                <el-button
                  type="primary"
                  :loading="loadingAcceptFormUpdate"
                  @click="
                    submitFormUpdateSeviceHealth(ruleFormUpdateServiceHealth)
                  "
                  >{{ $t("action_update") }}</el-button
                >
              </div>
            </el-form>
          </div>
        </el-dialog>
        <!-- dialog sắp xếp service-health -->
        <el-dialog
          v-model="showDialogSort"
          :title="$t('t_collocation')"
          class="dialog-service-health dialog-service-health-sort"
          :close-on-click-modal="false"
          align-center
          :before-close="handleClose"
        >
          <VueDraggableNext
            class="dragArea list-group w-full"
            :list="listServiceHealth"
          >
            <transition-group name="listDataSort">
              <div
                class="list-group-item bg-gray-300 m-1 p-1 rounded-md text-center"
                v-for="el in listServiceHealth"
                :key="el.id"
              >
                <div
                  style="
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    padding: 10px;
                  "
                >
                  <i class="bx bx-move label-icon align-middle fs-16 me-2"></i>
                  <div class="dragArea__name">{{ el.name }}</div>
                </div>
              </div>
            </transition-group>
          </VueDraggableNext>
          <template #footer>
            <span class="dialog-footer">
              <button
                type="button"
                class="btn btn-secondary btn-border"
                @click="updateSortServiceHealth(listServiceHealth)"
              >
                {{ $t("t-update") }}
              </button>
            </span>
          </template>
        </el-dialog>
      </div>
      <el-button
        @click="downloadCard"
        type="button"
        style="height: 40px"
        class="btn btn-secondary btn-border button-dashboard hide"
      >
      </el-button>
    </Layout>
  </div>
</template>
<link scoped lang="scss" src="./service-health.scss" />
